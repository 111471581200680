// source: mesop/protos/ui.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.mesop.ArgPath', null, global);
goog.exportSymbol('proto.mesop.ArgPathSegment', null, global);
goog.exportSymbol('proto.mesop.ArgPathSegment.TypeCase', null, global);
goog.exportSymbol('proto.mesop.BoolType', null, global);
goog.exportSymbol('proto.mesop.Border', null, global);
goog.exportSymbol('proto.mesop.BorderSide', null, global);
goog.exportSymbol('proto.mesop.ChangePrefersColorScheme', null, global);
goog.exportSymbol('proto.mesop.ClickEvent', null, global);
goog.exportSymbol('proto.mesop.CodeValue', null, global);
goog.exportSymbol('proto.mesop.CodeValue.ValueCase', null, global);
goog.exportSymbol('proto.mesop.Command', null, global);
goog.exportSymbol('proto.mesop.Command.CommandCase', null, global);
goog.exportSymbol('proto.mesop.Component', null, global);
goog.exportSymbol('proto.mesop.ComponentConfig', null, global);
goog.exportSymbol('proto.mesop.ComponentDiff', null, global);
goog.exportSymbol('proto.mesop.ComponentDiff.DiffType', null, global);
goog.exportSymbol('proto.mesop.ComponentDiff.UpdateStrategy', null, global);
goog.exportSymbol('proto.mesop.ComponentName', null, global);
goog.exportSymbol('proto.mesop.ComponentName.ModuleIdCase', null, global);
goog.exportSymbol('proto.mesop.ContextLine', null, global);
goog.exportSymbol('proto.mesop.DateRangePickerEvent', null, global);
goog.exportSymbol('proto.mesop.EdgeInsets', null, global);
goog.exportSymbol('proto.mesop.EditorField', null, global);
goog.exportSymbol('proto.mesop.ExperimentSettings', null, global);
goog.exportSymbol('proto.mesop.FieldType', null, global);
goog.exportSymbol('proto.mesop.FieldType.TypeCase', null, global);
goog.exportSymbol('proto.mesop.FloatType', null, global);
goog.exportSymbol('proto.mesop.FocusComponentCommand', null, global);
goog.exportSymbol('proto.mesop.InitRequest', null, global);
goog.exportSymbol('proto.mesop.IntType', null, global);
goog.exportSymbol('proto.mesop.Key', null, global);
goog.exportSymbol('proto.mesop.ListType', null, global);
goog.exportSymbol('proto.mesop.LiteralElement', null, global);
goog.exportSymbol('proto.mesop.LiteralElement.LiteralCase', null, global);
goog.exportSymbol('proto.mesop.LiteralType', null, global);
goog.exportSymbol('proto.mesop.NavigateCommand', null, global);
goog.exportSymbol('proto.mesop.NavigationEvent', null, global);
goog.exportSymbol('proto.mesop.QueryParam', null, global);
goog.exportSymbol('proto.mesop.RenderEvent', null, global);
goog.exportSymbol('proto.mesop.ResizeEvent', null, global);
goog.exportSymbol('proto.mesop.ScrollIntoViewCommand', null, global);
goog.exportSymbol('proto.mesop.ServerError', null, global);
goog.exportSymbol('proto.mesop.SetPageTitle', null, global);
goog.exportSymbol('proto.mesop.SetThemeDensity', null, global);
goog.exportSymbol('proto.mesop.SetThemeMode', null, global);
goog.exportSymbol('proto.mesop.Shortcut', null, global);
goog.exportSymbol('proto.mesop.SourceCodeLocation', null, global);
goog.exportSymbol('proto.mesop.StackFrame', null, global);
goog.exportSymbol('proto.mesop.State', null, global);
goog.exportSymbol('proto.mesop.States', null, global);
goog.exportSymbol('proto.mesop.StringType', null, global);
goog.exportSymbol('proto.mesop.StructType', null, global);
goog.exportSymbol('proto.mesop.Style', null, global);
goog.exportSymbol('proto.mesop.TextareaShortcutEvent', null, global);
goog.exportSymbol('proto.mesop.ThemeMode', null, global);
goog.exportSymbol('proto.mesop.ThemeSettings', null, global);
goog.exportSymbol('proto.mesop.Traceback', null, global);
goog.exportSymbol('proto.mesop.Type', null, global);
goog.exportSymbol('proto.mesop.UiRequest', null, global);
goog.exportSymbol('proto.mesop.UiRequest.TypeCase', null, global);
goog.exportSymbol('proto.mesop.UiResponse', null, global);
goog.exportSymbol('proto.mesop.UiResponse.TypeCase', null, global);
goog.exportSymbol('proto.mesop.UpdateQueryParam', null, global);
goog.exportSymbol('proto.mesop.UpdateStateEvent', null, global);
goog.exportSymbol('proto.mesop.UpdateStateEvent.TypeCase', null, global);
goog.exportSymbol('proto.mesop.UserDefinedType', null, global);
goog.exportSymbol('proto.mesop.UserDefinedType.Arg', null, global);
goog.exportSymbol('proto.mesop.UserEvent', null, global);
goog.exportSymbol('proto.mesop.UserEvent.TypeCase', null, global);
goog.exportSymbol('proto.mesop.ViewportSize', null, global);
goog.exportSymbol('proto.mesop.WebComponentType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.UiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mesop.UiRequest.oneofGroups_);
};
goog.inherits(proto.mesop.UiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.UiRequest.displayName = 'proto.mesop.UiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.InitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.InitRequest.repeatedFields_, null);
};
goog.inherits(proto.mesop.InitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.InitRequest.displayName = 'proto.mesop.InitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.QueryParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.QueryParam.repeatedFields_, null);
};
goog.inherits(proto.mesop.QueryParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.QueryParam.displayName = 'proto.mesop.QueryParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.UserEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.UserEvent.repeatedFields_, proto.mesop.UserEvent.oneofGroups_);
};
goog.inherits(proto.mesop.UserEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.UserEvent.displayName = 'proto.mesop.UserEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ThemeSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.ThemeSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ThemeSettings.displayName = 'proto.mesop.ThemeSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.CodeValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mesop.CodeValue.oneofGroups_);
};
goog.inherits(proto.mesop.CodeValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.CodeValue.displayName = 'proto.mesop.CodeValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ArgPath = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.ArgPath.repeatedFields_, null);
};
goog.inherits(proto.mesop.ArgPath, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ArgPath.displayName = 'proto.mesop.ArgPath';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ArgPathSegment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mesop.ArgPathSegment.oneofGroups_);
};
goog.inherits(proto.mesop.ArgPathSegment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ArgPathSegment.displayName = 'proto.mesop.ArgPathSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ClickEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.ClickEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ClickEvent.displayName = 'proto.mesop.ClickEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.TextareaShortcutEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.TextareaShortcutEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.TextareaShortcutEvent.displayName = 'proto.mesop.TextareaShortcutEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.Shortcut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.Shortcut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.Shortcut.displayName = 'proto.mesop.Shortcut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.DateRangePickerEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.DateRangePickerEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.DateRangePickerEvent.displayName = 'proto.mesop.DateRangePickerEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.NavigationEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.NavigationEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.NavigationEvent.displayName = 'proto.mesop.NavigationEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ResizeEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.ResizeEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ResizeEvent.displayName = 'proto.mesop.ResizeEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ChangePrefersColorScheme = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.ChangePrefersColorScheme, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ChangePrefersColorScheme.displayName = 'proto.mesop.ChangePrefersColorScheme';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ViewportSize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.ViewportSize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ViewportSize.displayName = 'proto.mesop.ViewportSize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.UiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mesop.UiResponse.oneofGroups_);
};
goog.inherits(proto.mesop.UiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.UiResponse.displayName = 'proto.mesop.UiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ServerError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.ServerError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ServerError.displayName = 'proto.mesop.ServerError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.Traceback = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.Traceback.repeatedFields_, null);
};
goog.inherits(proto.mesop.Traceback, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.Traceback.displayName = 'proto.mesop.Traceback';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.StackFrame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.StackFrame.repeatedFields_, null);
};
goog.inherits(proto.mesop.StackFrame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.StackFrame.displayName = 'proto.mesop.StackFrame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ContextLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.ContextLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ContextLine.displayName = 'proto.mesop.ContextLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.RenderEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.RenderEvent.repeatedFields_, null);
};
goog.inherits(proto.mesop.RenderEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.RenderEvent.displayName = 'proto.mesop.RenderEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ExperimentSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.ExperimentSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ExperimentSettings.displayName = 'proto.mesop.ExperimentSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.UpdateStateEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mesop.UpdateStateEvent.oneofGroups_);
};
goog.inherits(proto.mesop.UpdateStateEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.UpdateStateEvent.displayName = 'proto.mesop.UpdateStateEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.Command = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mesop.Command.oneofGroups_);
};
goog.inherits(proto.mesop.Command, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.Command.displayName = 'proto.mesop.Command';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.NavigateCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.NavigateCommand.repeatedFields_, null);
};
goog.inherits(proto.mesop.NavigateCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.NavigateCommand.displayName = 'proto.mesop.NavigateCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.UpdateQueryParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.UpdateQueryParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.UpdateQueryParam.displayName = 'proto.mesop.UpdateQueryParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ScrollIntoViewCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.ScrollIntoViewCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ScrollIntoViewCommand.displayName = 'proto.mesop.ScrollIntoViewCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.FocusComponentCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.FocusComponentCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.FocusComponentCommand.displayName = 'proto.mesop.FocusComponentCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.SetPageTitle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.SetPageTitle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.SetPageTitle.displayName = 'proto.mesop.SetPageTitle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.SetThemeMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.SetThemeMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.SetThemeMode.displayName = 'proto.mesop.SetThemeMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.SetThemeDensity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.SetThemeDensity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.SetThemeDensity.displayName = 'proto.mesop.SetThemeDensity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.States = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.States.repeatedFields_, null);
};
goog.inherits(proto.mesop.States, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.States.displayName = 'proto.mesop.States';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.State = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.State, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.State.displayName = 'proto.mesop.State';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.Component = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.Component.repeatedFields_, null);
};
goog.inherits(proto.mesop.Component, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.Component.displayName = 'proto.mesop.Component';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ComponentDiff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.ComponentDiff.repeatedFields_, null);
};
goog.inherits(proto.mesop.ComponentDiff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ComponentDiff.displayName = 'proto.mesop.ComponentDiff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.SourceCodeLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.SourceCodeLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.SourceCodeLocation.displayName = 'proto.mesop.SourceCodeLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.Key = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.Key, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.Key.displayName = 'proto.mesop.Key';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.Type = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.Type, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.Type.displayName = 'proto.mesop.Type';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.WebComponentType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.WebComponentType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.WebComponentType.displayName = 'proto.mesop.WebComponentType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.UserDefinedType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.UserDefinedType.repeatedFields_, null);
};
goog.inherits(proto.mesop.UserDefinedType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.UserDefinedType.displayName = 'proto.mesop.UserDefinedType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.UserDefinedType.Arg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.UserDefinedType.Arg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.UserDefinedType.Arg.displayName = 'proto.mesop.UserDefinedType.Arg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.Style = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.Style.repeatedFields_, null);
};
goog.inherits(proto.mesop.Style, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.Style.displayName = 'proto.mesop.Style';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.EdgeInsets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.EdgeInsets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.EdgeInsets.displayName = 'proto.mesop.EdgeInsets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.Border = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.Border, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.Border.displayName = 'proto.mesop.Border';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.BorderSide = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.BorderSide, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.BorderSide.displayName = 'proto.mesop.BorderSide';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ComponentConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.ComponentConfig.repeatedFields_, null);
};
goog.inherits(proto.mesop.ComponentConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ComponentConfig.displayName = 'proto.mesop.ComponentConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ComponentName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mesop.ComponentName.oneofGroups_);
};
goog.inherits(proto.mesop.ComponentName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ComponentName.displayName = 'proto.mesop.ComponentName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.EditorField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.EditorField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.EditorField.displayName = 'proto.mesop.EditorField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.FieldType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mesop.FieldType.oneofGroups_);
};
goog.inherits(proto.mesop.FieldType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.FieldType.displayName = 'proto.mesop.FieldType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.BoolType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.BoolType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.BoolType.displayName = 'proto.mesop.BoolType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.StructType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.StructType.repeatedFields_, null);
};
goog.inherits(proto.mesop.StructType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.StructType.displayName = 'proto.mesop.StructType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.IntType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.IntType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.IntType.displayName = 'proto.mesop.IntType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.FloatType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.FloatType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.FloatType.displayName = 'proto.mesop.FloatType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.StringType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.StringType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.StringType.displayName = 'proto.mesop.StringType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.LiteralType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.LiteralType.repeatedFields_, null);
};
goog.inherits(proto.mesop.LiteralType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.LiteralType.displayName = 'proto.mesop.LiteralType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.LiteralElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mesop.LiteralElement.oneofGroups_);
};
goog.inherits(proto.mesop.LiteralElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.LiteralElement.displayName = 'proto.mesop.LiteralElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.ListType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.ListType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.ListType.displayName = 'proto.mesop.ListType';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mesop.UiRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.mesop.UiRequest.TypeCase = {
  TYPE_NOT_SET: 0,
  INIT: 1,
  USER_EVENT: 2
};

/**
 * @return {proto.mesop.UiRequest.TypeCase}
 */
proto.mesop.UiRequest.prototype.getTypeCase = function() {
  return /** @type {proto.mesop.UiRequest.TypeCase} */(jspb.Message.computeOneofCase(this, proto.mesop.UiRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.UiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.UiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.UiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    path: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    init: (f = msg.getInit()) && proto.mesop.InitRequest.toObject(includeInstance, f),
    userEvent: (f = msg.getUserEvent()) && proto.mesop.UserEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.UiRequest}
 */
proto.mesop.UiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.UiRequest;
  return proto.mesop.UiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.UiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.UiRequest}
 */
proto.mesop.UiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    case 1:
      var value = new proto.mesop.InitRequest;
      reader.readMessage(value,proto.mesop.InitRequest.deserializeBinaryFromReader);
      msg.setInit(value);
      break;
    case 2:
      var value = new proto.mesop.UserEvent;
      reader.readMessage(value,proto.mesop.UserEvent.deserializeBinaryFromReader);
      msg.setUserEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.UiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.UiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.UiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.InitRequest.serializeBinaryToWriter
    );
  }
  f = message.getUserEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.UserEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional string path = 3;
 * @return {string}
 */
proto.mesop.UiRequest.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.UiRequest} returns this
 */
proto.mesop.UiRequest.prototype.setPath = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.UiRequest} returns this
 */
proto.mesop.UiRequest.prototype.clearPath = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UiRequest.prototype.hasPath = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional InitRequest init = 1;
 * @return {?proto.mesop.InitRequest}
 */
proto.mesop.UiRequest.prototype.getInit = function() {
  return /** @type{?proto.mesop.InitRequest} */ (
    jspb.Message.getWrapperField(this, proto.mesop.InitRequest, 1));
};


/**
 * @param {?proto.mesop.InitRequest|undefined} value
 * @return {!proto.mesop.UiRequest} returns this
*/
proto.mesop.UiRequest.prototype.setInit = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.mesop.UiRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UiRequest} returns this
 */
proto.mesop.UiRequest.prototype.clearInit = function() {
  return this.setInit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UiRequest.prototype.hasInit = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserEvent user_event = 2;
 * @return {?proto.mesop.UserEvent}
 */
proto.mesop.UiRequest.prototype.getUserEvent = function() {
  return /** @type{?proto.mesop.UserEvent} */ (
    jspb.Message.getWrapperField(this, proto.mesop.UserEvent, 2));
};


/**
 * @param {?proto.mesop.UserEvent|undefined} value
 * @return {!proto.mesop.UiRequest} returns this
*/
proto.mesop.UiRequest.prototype.setUserEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.mesop.UiRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UiRequest} returns this
 */
proto.mesop.UiRequest.prototype.clearUserEvent = function() {
  return this.setUserEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UiRequest.prototype.hasUserEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.InitRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.InitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.InitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.InitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.InitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewportSize: (f = msg.getViewportSize()) && proto.mesop.ViewportSize.toObject(includeInstance, f),
    themeSettings: (f = msg.getThemeSettings()) && proto.mesop.ThemeSettings.toObject(includeInstance, f),
    queryParamsList: jspb.Message.toObjectList(msg.getQueryParamsList(),
    proto.mesop.QueryParam.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.InitRequest}
 */
proto.mesop.InitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.InitRequest;
  return proto.mesop.InitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.InitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.InitRequest}
 */
proto.mesop.InitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.ViewportSize;
      reader.readMessage(value,proto.mesop.ViewportSize.deserializeBinaryFromReader);
      msg.setViewportSize(value);
      break;
    case 2:
      var value = new proto.mesop.ThemeSettings;
      reader.readMessage(value,proto.mesop.ThemeSettings.deserializeBinaryFromReader);
      msg.setThemeSettings(value);
      break;
    case 3:
      var value = new proto.mesop.QueryParam;
      reader.readMessage(value,proto.mesop.QueryParam.deserializeBinaryFromReader);
      msg.addQueryParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.InitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.InitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.InitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.InitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewportSize();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.ViewportSize.serializeBinaryToWriter
    );
  }
  f = message.getThemeSettings();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.ThemeSettings.serializeBinaryToWriter
    );
  }
  f = message.getQueryParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mesop.QueryParam.serializeBinaryToWriter
    );
  }
};


/**
 * optional ViewportSize viewport_size = 1;
 * @return {?proto.mesop.ViewportSize}
 */
proto.mesop.InitRequest.prototype.getViewportSize = function() {
  return /** @type{?proto.mesop.ViewportSize} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ViewportSize, 1));
};


/**
 * @param {?proto.mesop.ViewportSize|undefined} value
 * @return {!proto.mesop.InitRequest} returns this
*/
proto.mesop.InitRequest.prototype.setViewportSize = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.InitRequest} returns this
 */
proto.mesop.InitRequest.prototype.clearViewportSize = function() {
  return this.setViewportSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.InitRequest.prototype.hasViewportSize = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ThemeSettings theme_settings = 2;
 * @return {?proto.mesop.ThemeSettings}
 */
proto.mesop.InitRequest.prototype.getThemeSettings = function() {
  return /** @type{?proto.mesop.ThemeSettings} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ThemeSettings, 2));
};


/**
 * @param {?proto.mesop.ThemeSettings|undefined} value
 * @return {!proto.mesop.InitRequest} returns this
*/
proto.mesop.InitRequest.prototype.setThemeSettings = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.InitRequest} returns this
 */
proto.mesop.InitRequest.prototype.clearThemeSettings = function() {
  return this.setThemeSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.InitRequest.prototype.hasThemeSettings = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated QueryParam query_params = 3;
 * @return {!Array<!proto.mesop.QueryParam>}
 */
proto.mesop.InitRequest.prototype.getQueryParamsList = function() {
  return /** @type{!Array<!proto.mesop.QueryParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.QueryParam, 3));
};


/**
 * @param {!Array<!proto.mesop.QueryParam>} value
 * @return {!proto.mesop.InitRequest} returns this
*/
proto.mesop.InitRequest.prototype.setQueryParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mesop.QueryParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.QueryParam}
 */
proto.mesop.InitRequest.prototype.addQueryParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mesop.QueryParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.InitRequest} returns this
 */
proto.mesop.InitRequest.prototype.clearQueryParamsList = function() {
  return this.setQueryParamsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.QueryParam.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.QueryParam.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.QueryParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.QueryParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.QueryParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    valuesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.QueryParam}
 */
proto.mesop.QueryParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.QueryParam;
  return proto.mesop.QueryParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.QueryParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.QueryParam}
 */
proto.mesop.QueryParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.QueryParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.QueryParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.QueryParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.QueryParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.mesop.QueryParam.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.QueryParam} returns this
 */
proto.mesop.QueryParam.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.QueryParam} returns this
 */
proto.mesop.QueryParam.prototype.clearKey = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.QueryParam.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string values = 2;
 * @return {!Array<string>}
 */
proto.mesop.QueryParam.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mesop.QueryParam} returns this
 */
proto.mesop.QueryParam.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mesop.QueryParam} returns this
 */
proto.mesop.QueryParam.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.QueryParam} returns this
 */
proto.mesop.QueryParam.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.UserEvent.repeatedFields_ = [15];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mesop.UserEvent.oneofGroups_ = [[4,5,7,8,6,10,9,14,16,17,18]];

/**
 * @enum {number}
 */
proto.mesop.UserEvent.TypeCase = {
  TYPE_NOT_SET: 0,
  BOOL_VALUE: 4,
  STRING_VALUE: 5,
  DOUBLE_VALUE: 7,
  INT_VALUE: 8,
  NAVIGATION: 6,
  RESIZE: 10,
  BYTES_VALUE: 9,
  CHANGE_PREFERS_COLOR_SCHEME: 14,
  CLICK: 16,
  TEXTAREA_SHORTCUT: 17,
  DATE_RANGE_PICKER_EVENT: 18
};

/**
 * @return {proto.mesop.UserEvent.TypeCase}
 */
proto.mesop.UserEvent.prototype.getTypeCase = function() {
  return /** @type {proto.mesop.UserEvent.TypeCase} */(jspb.Message.computeOneofCase(this, proto.mesop.UserEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.UserEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.UserEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.UserEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UserEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    states: (f = msg.getStates()) && proto.mesop.States.toObject(includeInstance, f),
    handlerId: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    key: (f = msg.getKey()) && proto.mesop.Key.toObject(includeInstance, f),
    viewportSize: (f = msg.getViewportSize()) && proto.mesop.ViewportSize.toObject(includeInstance, f),
    themeSettings: (f = msg.getThemeSettings()) && proto.mesop.ThemeSettings.toObject(includeInstance, f),
    queryParamsList: jspb.Message.toObjectList(msg.getQueryParamsList(),
    proto.mesop.QueryParam.toObject, includeInstance),
    boolValue: (f = jspb.Message.getBooleanField(msg, 4)) == null ? undefined : f,
    stringValue: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    doubleValue: (f = jspb.Message.getOptionalFloatingPointField(msg, 7)) == null ? undefined : f,
    intValue: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    navigation: (f = msg.getNavigation()) && proto.mesop.NavigationEvent.toObject(includeInstance, f),
    resize: (f = msg.getResize()) && proto.mesop.ResizeEvent.toObject(includeInstance, f),
    bytesValue: msg.getBytesValue_asB64(),
    changePrefersColorScheme: (f = msg.getChangePrefersColorScheme()) && proto.mesop.ChangePrefersColorScheme.toObject(includeInstance, f),
    click: (f = msg.getClick()) && proto.mesop.ClickEvent.toObject(includeInstance, f),
    textareaShortcut: (f = msg.getTextareaShortcut()) && proto.mesop.TextareaShortcutEvent.toObject(includeInstance, f),
    dateRangePickerEvent: (f = msg.getDateRangePickerEvent()) && proto.mesop.DateRangePickerEvent.toObject(includeInstance, f),
    stateToken: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.UserEvent}
 */
proto.mesop.UserEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.UserEvent;
  return proto.mesop.UserEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.UserEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.UserEvent}
 */
proto.mesop.UserEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.States;
      reader.readMessage(value,proto.mesop.States.deserializeBinaryFromReader);
      msg.setStates(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandlerId(value);
      break;
    case 3:
      var value = new proto.mesop.Key;
      reader.readMessage(value,proto.mesop.Key.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 11:
      var value = new proto.mesop.ViewportSize;
      reader.readMessage(value,proto.mesop.ViewportSize.deserializeBinaryFromReader);
      msg.setViewportSize(value);
      break;
    case 13:
      var value = new proto.mesop.ThemeSettings;
      reader.readMessage(value,proto.mesop.ThemeSettings.deserializeBinaryFromReader);
      msg.setThemeSettings(value);
      break;
    case 15:
      var value = new proto.mesop.QueryParam;
      reader.readMessage(value,proto.mesop.QueryParam.deserializeBinaryFromReader);
      msg.addQueryParams(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleValue(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntValue(value);
      break;
    case 6:
      var value = new proto.mesop.NavigationEvent;
      reader.readMessage(value,proto.mesop.NavigationEvent.deserializeBinaryFromReader);
      msg.setNavigation(value);
      break;
    case 10:
      var value = new proto.mesop.ResizeEvent;
      reader.readMessage(value,proto.mesop.ResizeEvent.deserializeBinaryFromReader);
      msg.setResize(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBytesValue(value);
      break;
    case 14:
      var value = new proto.mesop.ChangePrefersColorScheme;
      reader.readMessage(value,proto.mesop.ChangePrefersColorScheme.deserializeBinaryFromReader);
      msg.setChangePrefersColorScheme(value);
      break;
    case 16:
      var value = new proto.mesop.ClickEvent;
      reader.readMessage(value,proto.mesop.ClickEvent.deserializeBinaryFromReader);
      msg.setClick(value);
      break;
    case 17:
      var value = new proto.mesop.TextareaShortcutEvent;
      reader.readMessage(value,proto.mesop.TextareaShortcutEvent.deserializeBinaryFromReader);
      msg.setTextareaShortcut(value);
      break;
    case 18:
      var value = new proto.mesop.DateRangePickerEvent;
      reader.readMessage(value,proto.mesop.DateRangePickerEvent.deserializeBinaryFromReader);
      msg.setDateRangePickerEvent(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.UserEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.UserEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.UserEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UserEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStates();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.States.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mesop.Key.serializeBinaryToWriter
    );
  }
  f = message.getViewportSize();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.mesop.ViewportSize.serializeBinaryToWriter
    );
  }
  f = message.getThemeSettings();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.mesop.ThemeSettings.serializeBinaryToWriter
    );
  }
  f = message.getQueryParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.mesop.QueryParam.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getNavigation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.mesop.NavigationEvent.serializeBinaryToWriter
    );
  }
  f = message.getResize();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.mesop.ResizeEvent.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBytes(
      9,
      f
    );
  }
  f = message.getChangePrefersColorScheme();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.mesop.ChangePrefersColorScheme.serializeBinaryToWriter
    );
  }
  f = message.getClick();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.mesop.ClickEvent.serializeBinaryToWriter
    );
  }
  f = message.getTextareaShortcut();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.mesop.TextareaShortcutEvent.serializeBinaryToWriter
    );
  }
  f = message.getDateRangePickerEvent();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.mesop.DateRangePickerEvent.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional States states = 1;
 * @return {?proto.mesop.States}
 */
proto.mesop.UserEvent.prototype.getStates = function() {
  return /** @type{?proto.mesop.States} */ (
    jspb.Message.getWrapperField(this, proto.mesop.States, 1));
};


/**
 * @param {?proto.mesop.States|undefined} value
 * @return {!proto.mesop.UserEvent} returns this
*/
proto.mesop.UserEvent.prototype.setStates = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearStates = function() {
  return this.setStates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasStates = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string handler_id = 2;
 * @return {string}
 */
proto.mesop.UserEvent.prototype.getHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.setHandlerId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearHandlerId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasHandlerId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Key key = 3;
 * @return {?proto.mesop.Key}
 */
proto.mesop.UserEvent.prototype.getKey = function() {
  return /** @type{?proto.mesop.Key} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Key, 3));
};


/**
 * @param {?proto.mesop.Key|undefined} value
 * @return {!proto.mesop.UserEvent} returns this
*/
proto.mesop.UserEvent.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ViewportSize viewport_size = 11;
 * @return {?proto.mesop.ViewportSize}
 */
proto.mesop.UserEvent.prototype.getViewportSize = function() {
  return /** @type{?proto.mesop.ViewportSize} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ViewportSize, 11));
};


/**
 * @param {?proto.mesop.ViewportSize|undefined} value
 * @return {!proto.mesop.UserEvent} returns this
*/
proto.mesop.UserEvent.prototype.setViewportSize = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearViewportSize = function() {
  return this.setViewportSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasViewportSize = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ThemeSettings theme_settings = 13;
 * @return {?proto.mesop.ThemeSettings}
 */
proto.mesop.UserEvent.prototype.getThemeSettings = function() {
  return /** @type{?proto.mesop.ThemeSettings} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ThemeSettings, 13));
};


/**
 * @param {?proto.mesop.ThemeSettings|undefined} value
 * @return {!proto.mesop.UserEvent} returns this
*/
proto.mesop.UserEvent.prototype.setThemeSettings = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearThemeSettings = function() {
  return this.setThemeSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasThemeSettings = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated QueryParam query_params = 15;
 * @return {!Array<!proto.mesop.QueryParam>}
 */
proto.mesop.UserEvent.prototype.getQueryParamsList = function() {
  return /** @type{!Array<!proto.mesop.QueryParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.QueryParam, 15));
};


/**
 * @param {!Array<!proto.mesop.QueryParam>} value
 * @return {!proto.mesop.UserEvent} returns this
*/
proto.mesop.UserEvent.prototype.setQueryParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.mesop.QueryParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.QueryParam}
 */
proto.mesop.UserEvent.prototype.addQueryParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.mesop.QueryParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearQueryParamsList = function() {
  return this.setQueryParamsList([]);
};


/**
 * optional bool bool_value = 4;
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.setBoolValue = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.mesop.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearBoolValue = function() {
  return jspb.Message.setOneofField(this, 4, proto.mesop.UserEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string string_value = 5;
 * @return {string}
 */
proto.mesop.UserEvent.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.mesop.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 5, proto.mesop.UserEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional double double_value = 7;
 * @return {number}
 */
proto.mesop.UserEvent.prototype.getDoubleValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.setDoubleValue = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.mesop.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearDoubleValue = function() {
  return jspb.Message.setOneofField(this, 7, proto.mesop.UserEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 int_value = 8;
 * @return {number}
 */
proto.mesop.UserEvent.prototype.getIntValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.setIntValue = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.mesop.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearIntValue = function() {
  return jspb.Message.setOneofField(this, 8, proto.mesop.UserEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasIntValue = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional NavigationEvent navigation = 6;
 * @return {?proto.mesop.NavigationEvent}
 */
proto.mesop.UserEvent.prototype.getNavigation = function() {
  return /** @type{?proto.mesop.NavigationEvent} */ (
    jspb.Message.getWrapperField(this, proto.mesop.NavigationEvent, 6));
};


/**
 * @param {?proto.mesop.NavigationEvent|undefined} value
 * @return {!proto.mesop.UserEvent} returns this
*/
proto.mesop.UserEvent.prototype.setNavigation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.mesop.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearNavigation = function() {
  return this.setNavigation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasNavigation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ResizeEvent resize = 10;
 * @return {?proto.mesop.ResizeEvent}
 */
proto.mesop.UserEvent.prototype.getResize = function() {
  return /** @type{?proto.mesop.ResizeEvent} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ResizeEvent, 10));
};


/**
 * @param {?proto.mesop.ResizeEvent|undefined} value
 * @return {!proto.mesop.UserEvent} returns this
*/
proto.mesop.UserEvent.prototype.setResize = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.mesop.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearResize = function() {
  return this.setResize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasResize = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bytes bytes_value = 9;
 * @return {!(string|Uint8Array)}
 */
proto.mesop.UserEvent.prototype.getBytesValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes bytes_value = 9;
 * This is a type-conversion wrapper around `getBytesValue()`
 * @return {string}
 */
proto.mesop.UserEvent.prototype.getBytesValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBytesValue()));
};


/**
 * optional bytes bytes_value = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBytesValue()`
 * @return {!Uint8Array}
 */
proto.mesop.UserEvent.prototype.getBytesValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBytesValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.setBytesValue = function(value) {
  return jspb.Message.setOneofField(this, 9, proto.mesop.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearBytesValue = function() {
  return jspb.Message.setOneofField(this, 9, proto.mesop.UserEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasBytesValue = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ChangePrefersColorScheme change_prefers_color_scheme = 14;
 * @return {?proto.mesop.ChangePrefersColorScheme}
 */
proto.mesop.UserEvent.prototype.getChangePrefersColorScheme = function() {
  return /** @type{?proto.mesop.ChangePrefersColorScheme} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ChangePrefersColorScheme, 14));
};


/**
 * @param {?proto.mesop.ChangePrefersColorScheme|undefined} value
 * @return {!proto.mesop.UserEvent} returns this
*/
proto.mesop.UserEvent.prototype.setChangePrefersColorScheme = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.mesop.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearChangePrefersColorScheme = function() {
  return this.setChangePrefersColorScheme(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasChangePrefersColorScheme = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ClickEvent click = 16;
 * @return {?proto.mesop.ClickEvent}
 */
proto.mesop.UserEvent.prototype.getClick = function() {
  return /** @type{?proto.mesop.ClickEvent} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ClickEvent, 16));
};


/**
 * @param {?proto.mesop.ClickEvent|undefined} value
 * @return {!proto.mesop.UserEvent} returns this
*/
proto.mesop.UserEvent.prototype.setClick = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.mesop.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearClick = function() {
  return this.setClick(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasClick = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional TextareaShortcutEvent textarea_shortcut = 17;
 * @return {?proto.mesop.TextareaShortcutEvent}
 */
proto.mesop.UserEvent.prototype.getTextareaShortcut = function() {
  return /** @type{?proto.mesop.TextareaShortcutEvent} */ (
    jspb.Message.getWrapperField(this, proto.mesop.TextareaShortcutEvent, 17));
};


/**
 * @param {?proto.mesop.TextareaShortcutEvent|undefined} value
 * @return {!proto.mesop.UserEvent} returns this
*/
proto.mesop.UserEvent.prototype.setTextareaShortcut = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.mesop.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearTextareaShortcut = function() {
  return this.setTextareaShortcut(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasTextareaShortcut = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional DateRangePickerEvent date_range_picker_event = 18;
 * @return {?proto.mesop.DateRangePickerEvent}
 */
proto.mesop.UserEvent.prototype.getDateRangePickerEvent = function() {
  return /** @type{?proto.mesop.DateRangePickerEvent} */ (
    jspb.Message.getWrapperField(this, proto.mesop.DateRangePickerEvent, 18));
};


/**
 * @param {?proto.mesop.DateRangePickerEvent|undefined} value
 * @return {!proto.mesop.UserEvent} returns this
*/
proto.mesop.UserEvent.prototype.setDateRangePickerEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.mesop.UserEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearDateRangePickerEvent = function() {
  return this.setDateRangePickerEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasDateRangePickerEvent = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string state_token = 12;
 * @return {string}
 */
proto.mesop.UserEvent.prototype.getStateToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.setStateToken = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.UserEvent} returns this
 */
proto.mesop.UserEvent.prototype.clearStateToken = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserEvent.prototype.hasStateToken = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ThemeSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ThemeSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ThemeSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ThemeSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    prefersDarkTheme: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    themeMode: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ThemeSettings}
 */
proto.mesop.ThemeSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ThemeSettings;
  return proto.mesop.ThemeSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ThemeSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ThemeSettings}
 */
proto.mesop.ThemeSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrefersDarkTheme(value);
      break;
    case 2:
      var value = /** @type {!proto.mesop.ThemeMode} */ (reader.readEnum());
      msg.setThemeMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ThemeSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ThemeSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ThemeSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ThemeSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {!proto.mesop.ThemeMode} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional bool prefers_dark_theme = 1;
 * @return {boolean}
 */
proto.mesop.ThemeSettings.prototype.getPrefersDarkTheme = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.ThemeSettings} returns this
 */
proto.mesop.ThemeSettings.prototype.setPrefersDarkTheme = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ThemeSettings} returns this
 */
proto.mesop.ThemeSettings.prototype.clearPrefersDarkTheme = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ThemeSettings.prototype.hasPrefersDarkTheme = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ThemeMode theme_mode = 2;
 * @return {!proto.mesop.ThemeMode}
 */
proto.mesop.ThemeSettings.prototype.getThemeMode = function() {
  return /** @type {!proto.mesop.ThemeMode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.mesop.ThemeMode} value
 * @return {!proto.mesop.ThemeSettings} returns this
 */
proto.mesop.ThemeSettings.prototype.setThemeMode = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ThemeSettings} returns this
 */
proto.mesop.ThemeSettings.prototype.clearThemeMode = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ThemeSettings.prototype.hasThemeMode = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mesop.CodeValue.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.mesop.CodeValue.ValueCase = {
  VALUE_NOT_SET: 0,
  STRING_VALUE: 1,
  BOOL_VALUE: 2,
  INT_VALUE: 3,
  DOUBLE_VALUE: 4,
  STRUCT_NAME: 5
};

/**
 * @return {proto.mesop.CodeValue.ValueCase}
 */
proto.mesop.CodeValue.prototype.getValueCase = function() {
  return /** @type {proto.mesop.CodeValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.mesop.CodeValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.CodeValue.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.CodeValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.CodeValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.CodeValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    stringValue: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    boolValue: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    intValue: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    doubleValue: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    structName: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.CodeValue}
 */
proto.mesop.CodeValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.CodeValue;
  return proto.mesop.CodeValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.CodeValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.CodeValue}
 */
proto.mesop.CodeValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.CodeValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.CodeValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.CodeValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.CodeValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string string_value = 1;
 * @return {string}
 */
proto.mesop.CodeValue.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.CodeValue} returns this
 */
proto.mesop.CodeValue.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.mesop.CodeValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.CodeValue} returns this
 */
proto.mesop.CodeValue.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.mesop.CodeValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.CodeValue.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool bool_value = 2;
 * @return {boolean}
 */
proto.mesop.CodeValue.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.CodeValue} returns this
 */
proto.mesop.CodeValue.prototype.setBoolValue = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.mesop.CodeValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.CodeValue} returns this
 */
proto.mesop.CodeValue.prototype.clearBoolValue = function() {
  return jspb.Message.setOneofField(this, 2, proto.mesop.CodeValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.CodeValue.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 int_value = 3;
 * @return {number}
 */
proto.mesop.CodeValue.prototype.getIntValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.CodeValue} returns this
 */
proto.mesop.CodeValue.prototype.setIntValue = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.mesop.CodeValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.CodeValue} returns this
 */
proto.mesop.CodeValue.prototype.clearIntValue = function() {
  return jspb.Message.setOneofField(this, 3, proto.mesop.CodeValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.CodeValue.prototype.hasIntValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double double_value = 4;
 * @return {number}
 */
proto.mesop.CodeValue.prototype.getDoubleValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.CodeValue} returns this
 */
proto.mesop.CodeValue.prototype.setDoubleValue = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.mesop.CodeValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.CodeValue} returns this
 */
proto.mesop.CodeValue.prototype.clearDoubleValue = function() {
  return jspb.Message.setOneofField(this, 4, proto.mesop.CodeValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.CodeValue.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string struct_name = 5;
 * @return {string}
 */
proto.mesop.CodeValue.prototype.getStructName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.CodeValue} returns this
 */
proto.mesop.CodeValue.prototype.setStructName = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.mesop.CodeValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.CodeValue} returns this
 */
proto.mesop.CodeValue.prototype.clearStructName = function() {
  return jspb.Message.setOneofField(this, 5, proto.mesop.CodeValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.CodeValue.prototype.hasStructName = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.ArgPath.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ArgPath.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ArgPath.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ArgPath} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ArgPath.toObject = function(includeInstance, msg) {
  var f, obj = {
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.mesop.ArgPathSegment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ArgPath}
 */
proto.mesop.ArgPath.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ArgPath;
  return proto.mesop.ArgPath.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ArgPath} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ArgPath}
 */
proto.mesop.ArgPath.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.ArgPathSegment;
      reader.readMessage(value,proto.mesop.ArgPathSegment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ArgPath.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ArgPath.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ArgPath} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ArgPath.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mesop.ArgPathSegment.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ArgPathSegment segments = 1;
 * @return {!Array<!proto.mesop.ArgPathSegment>}
 */
proto.mesop.ArgPath.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.mesop.ArgPathSegment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.ArgPathSegment, 1));
};


/**
 * @param {!Array<!proto.mesop.ArgPathSegment>} value
 * @return {!proto.mesop.ArgPath} returns this
*/
proto.mesop.ArgPath.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mesop.ArgPathSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.ArgPathSegment}
 */
proto.mesop.ArgPath.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mesop.ArgPathSegment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.ArgPath} returns this
 */
proto.mesop.ArgPath.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mesop.ArgPathSegment.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.mesop.ArgPathSegment.TypeCase = {
  TYPE_NOT_SET: 0,
  KEYWORD_ARGUMENT: 1,
  LIST_INDEX: 2
};

/**
 * @return {proto.mesop.ArgPathSegment.TypeCase}
 */
proto.mesop.ArgPathSegment.prototype.getTypeCase = function() {
  return /** @type {proto.mesop.ArgPathSegment.TypeCase} */(jspb.Message.computeOneofCase(this, proto.mesop.ArgPathSegment.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ArgPathSegment.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ArgPathSegment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ArgPathSegment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ArgPathSegment.toObject = function(includeInstance, msg) {
  var f, obj = {
    keywordArgument: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    listIndex: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ArgPathSegment}
 */
proto.mesop.ArgPathSegment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ArgPathSegment;
  return proto.mesop.ArgPathSegment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ArgPathSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ArgPathSegment}
 */
proto.mesop.ArgPathSegment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeywordArgument(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setListIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ArgPathSegment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ArgPathSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ArgPathSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ArgPathSegment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string keyword_argument = 1;
 * @return {string}
 */
proto.mesop.ArgPathSegment.prototype.getKeywordArgument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.ArgPathSegment} returns this
 */
proto.mesop.ArgPathSegment.prototype.setKeywordArgument = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.mesop.ArgPathSegment.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ArgPathSegment} returns this
 */
proto.mesop.ArgPathSegment.prototype.clearKeywordArgument = function() {
  return jspb.Message.setOneofField(this, 1, proto.mesop.ArgPathSegment.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ArgPathSegment.prototype.hasKeywordArgument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 list_index = 2;
 * @return {number}
 */
proto.mesop.ArgPathSegment.prototype.getListIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.ArgPathSegment} returns this
 */
proto.mesop.ArgPathSegment.prototype.setListIndex = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.mesop.ArgPathSegment.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ArgPathSegment} returns this
 */
proto.mesop.ArgPathSegment.prototype.clearListIndex = function() {
  return jspb.Message.setOneofField(this, 2, proto.mesop.ArgPathSegment.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ArgPathSegment.prototype.hasListIndex = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ClickEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ClickEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ClickEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ClickEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    isTarget: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ClickEvent}
 */
proto.mesop.ClickEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ClickEvent;
  return proto.mesop.ClickEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ClickEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ClickEvent}
 */
proto.mesop.ClickEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ClickEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ClickEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ClickEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ClickEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_target = 1;
 * @return {boolean}
 */
proto.mesop.ClickEvent.prototype.getIsTarget = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.ClickEvent} returns this
 */
proto.mesop.ClickEvent.prototype.setIsTarget = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ClickEvent} returns this
 */
proto.mesop.ClickEvent.prototype.clearIsTarget = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ClickEvent.prototype.hasIsTarget = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.TextareaShortcutEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.TextareaShortcutEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.TextareaShortcutEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.TextareaShortcutEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    shortcut: (f = msg.getShortcut()) && proto.mesop.Shortcut.toObject(includeInstance, f),
    stringValue: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.TextareaShortcutEvent}
 */
proto.mesop.TextareaShortcutEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.TextareaShortcutEvent;
  return proto.mesop.TextareaShortcutEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.TextareaShortcutEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.TextareaShortcutEvent}
 */
proto.mesop.TextareaShortcutEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.Shortcut;
      reader.readMessage(value,proto.mesop.Shortcut.deserializeBinaryFromReader);
      msg.setShortcut(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.TextareaShortcutEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.TextareaShortcutEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.TextareaShortcutEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.TextareaShortcutEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getShortcut();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.Shortcut.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Shortcut shortcut = 1;
 * @return {?proto.mesop.Shortcut}
 */
proto.mesop.TextareaShortcutEvent.prototype.getShortcut = function() {
  return /** @type{?proto.mesop.Shortcut} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Shortcut, 1));
};


/**
 * @param {?proto.mesop.Shortcut|undefined} value
 * @return {!proto.mesop.TextareaShortcutEvent} returns this
*/
proto.mesop.TextareaShortcutEvent.prototype.setShortcut = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.TextareaShortcutEvent} returns this
 */
proto.mesop.TextareaShortcutEvent.prototype.clearShortcut = function() {
  return this.setShortcut(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.TextareaShortcutEvent.prototype.hasShortcut = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string string_value = 2;
 * @return {string}
 */
proto.mesop.TextareaShortcutEvent.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.TextareaShortcutEvent} returns this
 */
proto.mesop.TextareaShortcutEvent.prototype.setStringValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.TextareaShortcutEvent} returns this
 */
proto.mesop.TextareaShortcutEvent.prototype.clearStringValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.TextareaShortcutEvent.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.Shortcut.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.Shortcut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.Shortcut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Shortcut.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    shift: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    ctrl: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f,
    alt: (f = jspb.Message.getBooleanField(msg, 4)) == null ? undefined : f,
    meta: (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.Shortcut}
 */
proto.mesop.Shortcut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.Shortcut;
  return proto.mesop.Shortcut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.Shortcut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.Shortcut}
 */
proto.mesop.Shortcut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShift(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCtrl(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlt(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.Shortcut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.Shortcut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.Shortcut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Shortcut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.mesop.Shortcut.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Shortcut} returns this
 */
proto.mesop.Shortcut.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Shortcut} returns this
 */
proto.mesop.Shortcut.prototype.clearKey = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Shortcut.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool shift = 2;
 * @return {boolean}
 */
proto.mesop.Shortcut.prototype.getShift = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.Shortcut} returns this
 */
proto.mesop.Shortcut.prototype.setShift = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Shortcut} returns this
 */
proto.mesop.Shortcut.prototype.clearShift = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Shortcut.prototype.hasShift = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool ctrl = 3;
 * @return {boolean}
 */
proto.mesop.Shortcut.prototype.getCtrl = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.Shortcut} returns this
 */
proto.mesop.Shortcut.prototype.setCtrl = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Shortcut} returns this
 */
proto.mesop.Shortcut.prototype.clearCtrl = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Shortcut.prototype.hasCtrl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool alt = 4;
 * @return {boolean}
 */
proto.mesop.Shortcut.prototype.getAlt = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.Shortcut} returns this
 */
proto.mesop.Shortcut.prototype.setAlt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Shortcut} returns this
 */
proto.mesop.Shortcut.prototype.clearAlt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Shortcut.prototype.hasAlt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool meta = 5;
 * @return {boolean}
 */
proto.mesop.Shortcut.prototype.getMeta = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.Shortcut} returns this
 */
proto.mesop.Shortcut.prototype.setMeta = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Shortcut} returns this
 */
proto.mesop.Shortcut.prototype.clearMeta = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Shortcut.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.DateRangePickerEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.DateRangePickerEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.DateRangePickerEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.DateRangePickerEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDate: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    endDate: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.DateRangePickerEvent}
 */
proto.mesop.DateRangePickerEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.DateRangePickerEvent;
  return proto.mesop.DateRangePickerEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.DateRangePickerEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.DateRangePickerEvent}
 */
proto.mesop.DateRangePickerEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.DateRangePickerEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.DateRangePickerEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.DateRangePickerEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.DateRangePickerEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string start_date = 1;
 * @return {string}
 */
proto.mesop.DateRangePickerEvent.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.DateRangePickerEvent} returns this
 */
proto.mesop.DateRangePickerEvent.prototype.setStartDate = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.DateRangePickerEvent} returns this
 */
proto.mesop.DateRangePickerEvent.prototype.clearStartDate = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.DateRangePickerEvent.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string end_date = 2;
 * @return {string}
 */
proto.mesop.DateRangePickerEvent.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.DateRangePickerEvent} returns this
 */
proto.mesop.DateRangePickerEvent.prototype.setEndDate = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.DateRangePickerEvent} returns this
 */
proto.mesop.DateRangePickerEvent.prototype.clearEndDate = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.DateRangePickerEvent.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.NavigationEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.NavigationEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.NavigationEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.NavigationEvent.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.NavigationEvent}
 */
proto.mesop.NavigationEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.NavigationEvent;
  return proto.mesop.NavigationEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.NavigationEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.NavigationEvent}
 */
proto.mesop.NavigationEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.NavigationEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.NavigationEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.NavigationEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.NavigationEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ResizeEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ResizeEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ResizeEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ResizeEvent.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ResizeEvent}
 */
proto.mesop.ResizeEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ResizeEvent;
  return proto.mesop.ResizeEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ResizeEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ResizeEvent}
 */
proto.mesop.ResizeEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ResizeEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ResizeEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ResizeEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ResizeEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ChangePrefersColorScheme.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ChangePrefersColorScheme.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ChangePrefersColorScheme} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ChangePrefersColorScheme.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ChangePrefersColorScheme}
 */
proto.mesop.ChangePrefersColorScheme.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ChangePrefersColorScheme;
  return proto.mesop.ChangePrefersColorScheme.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ChangePrefersColorScheme} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ChangePrefersColorScheme}
 */
proto.mesop.ChangePrefersColorScheme.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ChangePrefersColorScheme.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ChangePrefersColorScheme.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ChangePrefersColorScheme} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ChangePrefersColorScheme.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ViewportSize.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ViewportSize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ViewportSize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ViewportSize.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    height: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ViewportSize}
 */
proto.mesop.ViewportSize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ViewportSize;
  return proto.mesop.ViewportSize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ViewportSize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ViewportSize}
 */
proto.mesop.ViewportSize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ViewportSize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ViewportSize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ViewportSize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ViewportSize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 width = 1;
 * @return {number}
 */
proto.mesop.ViewportSize.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.ViewportSize} returns this
 */
proto.mesop.ViewportSize.prototype.setWidth = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ViewportSize} returns this
 */
proto.mesop.ViewportSize.prototype.clearWidth = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ViewportSize.prototype.hasWidth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 height = 2;
 * @return {number}
 */
proto.mesop.ViewportSize.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.ViewportSize} returns this
 */
proto.mesop.ViewportSize.prototype.setHeight = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ViewportSize} returns this
 */
proto.mesop.ViewportSize.prototype.clearHeight = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ViewportSize.prototype.hasHeight = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mesop.UiResponse.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.mesop.UiResponse.TypeCase = {
  TYPE_NOT_SET: 0,
  RENDER: 1,
  ERROR: 2,
  UPDATE_STATE_EVENT: 3
};

/**
 * @return {proto.mesop.UiResponse.TypeCase}
 */
proto.mesop.UiResponse.prototype.getTypeCase = function() {
  return /** @type {proto.mesop.UiResponse.TypeCase} */(jspb.Message.computeOneofCase(this, proto.mesop.UiResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.UiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.UiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.UiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    render: (f = msg.getRender()) && proto.mesop.RenderEvent.toObject(includeInstance, f),
    error: (f = msg.getError()) && proto.mesop.ServerError.toObject(includeInstance, f),
    updateStateEvent: (f = msg.getUpdateStateEvent()) && proto.mesop.UpdateStateEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.UiResponse}
 */
proto.mesop.UiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.UiResponse;
  return proto.mesop.UiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.UiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.UiResponse}
 */
proto.mesop.UiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.RenderEvent;
      reader.readMessage(value,proto.mesop.RenderEvent.deserializeBinaryFromReader);
      msg.setRender(value);
      break;
    case 2:
      var value = new proto.mesop.ServerError;
      reader.readMessage(value,proto.mesop.ServerError.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new proto.mesop.UpdateStateEvent;
      reader.readMessage(value,proto.mesop.UpdateStateEvent.deserializeBinaryFromReader);
      msg.setUpdateStateEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.UiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.UiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.UiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRender();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.RenderEvent.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.ServerError.serializeBinaryToWriter
    );
  }
  f = message.getUpdateStateEvent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mesop.UpdateStateEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional RenderEvent render = 1;
 * @return {?proto.mesop.RenderEvent}
 */
proto.mesop.UiResponse.prototype.getRender = function() {
  return /** @type{?proto.mesop.RenderEvent} */ (
    jspb.Message.getWrapperField(this, proto.mesop.RenderEvent, 1));
};


/**
 * @param {?proto.mesop.RenderEvent|undefined} value
 * @return {!proto.mesop.UiResponse} returns this
*/
proto.mesop.UiResponse.prototype.setRender = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.mesop.UiResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UiResponse} returns this
 */
proto.mesop.UiResponse.prototype.clearRender = function() {
  return this.setRender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UiResponse.prototype.hasRender = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ServerError error = 2;
 * @return {?proto.mesop.ServerError}
 */
proto.mesop.UiResponse.prototype.getError = function() {
  return /** @type{?proto.mesop.ServerError} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ServerError, 2));
};


/**
 * @param {?proto.mesop.ServerError|undefined} value
 * @return {!proto.mesop.UiResponse} returns this
*/
proto.mesop.UiResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.mesop.UiResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UiResponse} returns this
 */
proto.mesop.UiResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UiResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UpdateStateEvent update_state_event = 3;
 * @return {?proto.mesop.UpdateStateEvent}
 */
proto.mesop.UiResponse.prototype.getUpdateStateEvent = function() {
  return /** @type{?proto.mesop.UpdateStateEvent} */ (
    jspb.Message.getWrapperField(this, proto.mesop.UpdateStateEvent, 3));
};


/**
 * @param {?proto.mesop.UpdateStateEvent|undefined} value
 * @return {!proto.mesop.UiResponse} returns this
*/
proto.mesop.UiResponse.prototype.setUpdateStateEvent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.mesop.UiResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UiResponse} returns this
 */
proto.mesop.UiResponse.prototype.clearUpdateStateEvent = function() {
  return this.setUpdateStateEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UiResponse.prototype.hasUpdateStateEvent = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ServerError.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ServerError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ServerError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ServerError.toObject = function(includeInstance, msg) {
  var f, obj = {
    exception: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    traceback: (f = msg.getTraceback()) && proto.mesop.Traceback.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ServerError}
 */
proto.mesop.ServerError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ServerError;
  return proto.mesop.ServerError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ServerError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ServerError}
 */
proto.mesop.ServerError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setException(value);
      break;
    case 2:
      var value = new proto.mesop.Traceback;
      reader.readMessage(value,proto.mesop.Traceback.deserializeBinaryFromReader);
      msg.setTraceback(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ServerError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ServerError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ServerError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ServerError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTraceback();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.Traceback.serializeBinaryToWriter
    );
  }
};


/**
 * optional string exception = 1;
 * @return {string}
 */
proto.mesop.ServerError.prototype.getException = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.ServerError} returns this
 */
proto.mesop.ServerError.prototype.setException = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ServerError} returns this
 */
proto.mesop.ServerError.prototype.clearException = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ServerError.prototype.hasException = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Traceback traceback = 2;
 * @return {?proto.mesop.Traceback}
 */
proto.mesop.ServerError.prototype.getTraceback = function() {
  return /** @type{?proto.mesop.Traceback} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Traceback, 2));
};


/**
 * @param {?proto.mesop.Traceback|undefined} value
 * @return {!proto.mesop.ServerError} returns this
*/
proto.mesop.ServerError.prototype.setTraceback = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.ServerError} returns this
 */
proto.mesop.ServerError.prototype.clearTraceback = function() {
  return this.setTraceback(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ServerError.prototype.hasTraceback = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.Traceback.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.Traceback.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.Traceback.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.Traceback} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Traceback.toObject = function(includeInstance, msg) {
  var f, obj = {
    framesList: jspb.Message.toObjectList(msg.getFramesList(),
    proto.mesop.StackFrame.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.Traceback}
 */
proto.mesop.Traceback.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.Traceback;
  return proto.mesop.Traceback.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.Traceback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.Traceback}
 */
proto.mesop.Traceback.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.StackFrame;
      reader.readMessage(value,proto.mesop.StackFrame.deserializeBinaryFromReader);
      msg.addFrames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.Traceback.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.Traceback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.Traceback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Traceback.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFramesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mesop.StackFrame.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StackFrame frames = 1;
 * @return {!Array<!proto.mesop.StackFrame>}
 */
proto.mesop.Traceback.prototype.getFramesList = function() {
  return /** @type{!Array<!proto.mesop.StackFrame>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.StackFrame, 1));
};


/**
 * @param {!Array<!proto.mesop.StackFrame>} value
 * @return {!proto.mesop.Traceback} returns this
*/
proto.mesop.Traceback.prototype.setFramesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mesop.StackFrame=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.StackFrame}
 */
proto.mesop.Traceback.prototype.addFrames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mesop.StackFrame, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.Traceback} returns this
 */
proto.mesop.Traceback.prototype.clearFramesList = function() {
  return this.setFramesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.StackFrame.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.StackFrame.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.StackFrame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.StackFrame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.StackFrame.toObject = function(includeInstance, msg) {
  var f, obj = {
    filename: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    codeName: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lineNumber: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    linesList: jspb.Message.toObjectList(msg.getLinesList(),
    proto.mesop.ContextLine.toObject, includeInstance),
    isAppCode: (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.StackFrame}
 */
proto.mesop.StackFrame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.StackFrame;
  return proto.mesop.StackFrame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.StackFrame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.StackFrame}
 */
proto.mesop.StackFrame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCodeName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLineNumber(value);
      break;
    case 4:
      var value = new proto.mesop.ContextLine;
      reader.readMessage(value,proto.mesop.ContextLine.deserializeBinaryFromReader);
      msg.addLines(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAppCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.StackFrame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.StackFrame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.StackFrame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.StackFrame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.mesop.ContextLine.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string filename = 1;
 * @return {string}
 */
proto.mesop.StackFrame.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.StackFrame} returns this
 */
proto.mesop.StackFrame.prototype.setFilename = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.StackFrame} returns this
 */
proto.mesop.StackFrame.prototype.clearFilename = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.StackFrame.prototype.hasFilename = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string code_name = 2;
 * @return {string}
 */
proto.mesop.StackFrame.prototype.getCodeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.StackFrame} returns this
 */
proto.mesop.StackFrame.prototype.setCodeName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.StackFrame} returns this
 */
proto.mesop.StackFrame.prototype.clearCodeName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.StackFrame.prototype.hasCodeName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 line_number = 3;
 * @return {number}
 */
proto.mesop.StackFrame.prototype.getLineNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.StackFrame} returns this
 */
proto.mesop.StackFrame.prototype.setLineNumber = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.StackFrame} returns this
 */
proto.mesop.StackFrame.prototype.clearLineNumber = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.StackFrame.prototype.hasLineNumber = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated ContextLine lines = 4;
 * @return {!Array<!proto.mesop.ContextLine>}
 */
proto.mesop.StackFrame.prototype.getLinesList = function() {
  return /** @type{!Array<!proto.mesop.ContextLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.ContextLine, 4));
};


/**
 * @param {!Array<!proto.mesop.ContextLine>} value
 * @return {!proto.mesop.StackFrame} returns this
*/
proto.mesop.StackFrame.prototype.setLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.mesop.ContextLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.ContextLine}
 */
proto.mesop.StackFrame.prototype.addLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.mesop.ContextLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.StackFrame} returns this
 */
proto.mesop.StackFrame.prototype.clearLinesList = function() {
  return this.setLinesList([]);
};


/**
 * optional bool is_app_code = 5;
 * @return {boolean}
 */
proto.mesop.StackFrame.prototype.getIsAppCode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.StackFrame} returns this
 */
proto.mesop.StackFrame.prototype.setIsAppCode = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.StackFrame} returns this
 */
proto.mesop.StackFrame.prototype.clearIsAppCode = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.StackFrame.prototype.hasIsAppCode = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ContextLine.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ContextLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ContextLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ContextLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    isCaller: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ContextLine}
 */
proto.mesop.ContextLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ContextLine;
  return proto.mesop.ContextLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ContextLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ContextLine}
 */
proto.mesop.ContextLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCaller(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ContextLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ContextLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ContextLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ContextLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.mesop.ContextLine.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.ContextLine} returns this
 */
proto.mesop.ContextLine.prototype.setCode = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ContextLine} returns this
 */
proto.mesop.ContextLine.prototype.clearCode = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ContextLine.prototype.hasCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_caller = 2;
 * @return {boolean}
 */
proto.mesop.ContextLine.prototype.getIsCaller = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.ContextLine} returns this
 */
proto.mesop.ContextLine.prototype.setIsCaller = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ContextLine} returns this
 */
proto.mesop.ContextLine.prototype.clearIsCaller = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ContextLine.prototype.hasIsCaller = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.RenderEvent.repeatedFields_ = [4,6,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.RenderEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.RenderEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.RenderEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.RenderEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    rootComponent: (f = msg.getRootComponent()) && proto.mesop.Component.toObject(includeInstance, f),
    componentDiff: (f = msg.getComponentDiff()) && proto.mesop.ComponentDiff.toObject(includeInstance, f),
    title: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    commandsList: jspb.Message.toObjectList(msg.getCommandsList(),
    proto.mesop.Command.toObject, includeInstance),
    jsModulesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    componentConfigsList: jspb.Message.toObjectList(msg.getComponentConfigsList(),
    proto.mesop.ComponentConfig.toObject, includeInstance),
    experimentSettings: (f = msg.getExperimentSettings()) && proto.mesop.ExperimentSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.RenderEvent}
 */
proto.mesop.RenderEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.RenderEvent;
  return proto.mesop.RenderEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.RenderEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.RenderEvent}
 */
proto.mesop.RenderEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.Component;
      reader.readMessage(value,proto.mesop.Component.deserializeBinaryFromReader);
      msg.setRootComponent(value);
      break;
    case 2:
      var value = new proto.mesop.ComponentDiff;
      reader.readMessage(value,proto.mesop.ComponentDiff.deserializeBinaryFromReader);
      msg.setComponentDiff(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = new proto.mesop.Command;
      reader.readMessage(value,proto.mesop.Command.deserializeBinaryFromReader);
      msg.addCommands(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addJsModules(value);
      break;
    case 5:
      var value = new proto.mesop.ComponentConfig;
      reader.readMessage(value,proto.mesop.ComponentConfig.deserializeBinaryFromReader);
      msg.addComponentConfigs(value);
      break;
    case 7:
      var value = new proto.mesop.ExperimentSettings;
      reader.readMessage(value,proto.mesop.ExperimentSettings.deserializeBinaryFromReader);
      msg.setExperimentSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.RenderEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.RenderEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.RenderEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.RenderEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRootComponent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.Component.serializeBinaryToWriter
    );
  }
  f = message.getComponentDiff();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.ComponentDiff.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCommandsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.mesop.Command.serializeBinaryToWriter
    );
  }
  f = message.getJsModulesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getComponentConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.mesop.ComponentConfig.serializeBinaryToWriter
    );
  }
  f = message.getExperimentSettings();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mesop.ExperimentSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional Component root_component = 1;
 * @return {?proto.mesop.Component}
 */
proto.mesop.RenderEvent.prototype.getRootComponent = function() {
  return /** @type{?proto.mesop.Component} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Component, 1));
};


/**
 * @param {?proto.mesop.Component|undefined} value
 * @return {!proto.mesop.RenderEvent} returns this
*/
proto.mesop.RenderEvent.prototype.setRootComponent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.RenderEvent} returns this
 */
proto.mesop.RenderEvent.prototype.clearRootComponent = function() {
  return this.setRootComponent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.RenderEvent.prototype.hasRootComponent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ComponentDiff component_diff = 2;
 * @return {?proto.mesop.ComponentDiff}
 */
proto.mesop.RenderEvent.prototype.getComponentDiff = function() {
  return /** @type{?proto.mesop.ComponentDiff} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ComponentDiff, 2));
};


/**
 * @param {?proto.mesop.ComponentDiff|undefined} value
 * @return {!proto.mesop.RenderEvent} returns this
*/
proto.mesop.RenderEvent.prototype.setComponentDiff = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.RenderEvent} returns this
 */
proto.mesop.RenderEvent.prototype.clearComponentDiff = function() {
  return this.setComponentDiff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.RenderEvent.prototype.hasComponentDiff = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.mesop.RenderEvent.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.RenderEvent} returns this
 */
proto.mesop.RenderEvent.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.RenderEvent} returns this
 */
proto.mesop.RenderEvent.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.RenderEvent.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Command commands = 4;
 * @return {!Array<!proto.mesop.Command>}
 */
proto.mesop.RenderEvent.prototype.getCommandsList = function() {
  return /** @type{!Array<!proto.mesop.Command>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.Command, 4));
};


/**
 * @param {!Array<!proto.mesop.Command>} value
 * @return {!proto.mesop.RenderEvent} returns this
*/
proto.mesop.RenderEvent.prototype.setCommandsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.mesop.Command=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.Command}
 */
proto.mesop.RenderEvent.prototype.addCommands = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.mesop.Command, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.RenderEvent} returns this
 */
proto.mesop.RenderEvent.prototype.clearCommandsList = function() {
  return this.setCommandsList([]);
};


/**
 * repeated string js_modules = 6;
 * @return {!Array<string>}
 */
proto.mesop.RenderEvent.prototype.getJsModulesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mesop.RenderEvent} returns this
 */
proto.mesop.RenderEvent.prototype.setJsModulesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mesop.RenderEvent} returns this
 */
proto.mesop.RenderEvent.prototype.addJsModules = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.RenderEvent} returns this
 */
proto.mesop.RenderEvent.prototype.clearJsModulesList = function() {
  return this.setJsModulesList([]);
};


/**
 * repeated ComponentConfig component_configs = 5;
 * @return {!Array<!proto.mesop.ComponentConfig>}
 */
proto.mesop.RenderEvent.prototype.getComponentConfigsList = function() {
  return /** @type{!Array<!proto.mesop.ComponentConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.ComponentConfig, 5));
};


/**
 * @param {!Array<!proto.mesop.ComponentConfig>} value
 * @return {!proto.mesop.RenderEvent} returns this
*/
proto.mesop.RenderEvent.prototype.setComponentConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.mesop.ComponentConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.ComponentConfig}
 */
proto.mesop.RenderEvent.prototype.addComponentConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.mesop.ComponentConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.RenderEvent} returns this
 */
proto.mesop.RenderEvent.prototype.clearComponentConfigsList = function() {
  return this.setComponentConfigsList([]);
};


/**
 * optional ExperimentSettings experiment_settings = 7;
 * @return {?proto.mesop.ExperimentSettings}
 */
proto.mesop.RenderEvent.prototype.getExperimentSettings = function() {
  return /** @type{?proto.mesop.ExperimentSettings} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ExperimentSettings, 7));
};


/**
 * @param {?proto.mesop.ExperimentSettings|undefined} value
 * @return {!proto.mesop.RenderEvent} returns this
*/
proto.mesop.RenderEvent.prototype.setExperimentSettings = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.RenderEvent} returns this
 */
proto.mesop.RenderEvent.prototype.clearExperimentSettings = function() {
  return this.setExperimentSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.RenderEvent.prototype.hasExperimentSettings = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ExperimentSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ExperimentSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ExperimentSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ExperimentSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    experimentalEditorToolbarEnabled: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    concurrentUpdatesEnabled: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    websocketsEnabled: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ExperimentSettings}
 */
proto.mesop.ExperimentSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ExperimentSettings;
  return proto.mesop.ExperimentSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ExperimentSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ExperimentSettings}
 */
proto.mesop.ExperimentSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExperimentalEditorToolbarEnabled(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConcurrentUpdatesEnabled(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWebsocketsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ExperimentSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ExperimentSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ExperimentSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ExperimentSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool experimental_editor_toolbar_enabled = 1;
 * @return {boolean}
 */
proto.mesop.ExperimentSettings.prototype.getExperimentalEditorToolbarEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.ExperimentSettings} returns this
 */
proto.mesop.ExperimentSettings.prototype.setExperimentalEditorToolbarEnabled = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ExperimentSettings} returns this
 */
proto.mesop.ExperimentSettings.prototype.clearExperimentalEditorToolbarEnabled = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ExperimentSettings.prototype.hasExperimentalEditorToolbarEnabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool concurrent_updates_enabled = 2;
 * @return {boolean}
 */
proto.mesop.ExperimentSettings.prototype.getConcurrentUpdatesEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.ExperimentSettings} returns this
 */
proto.mesop.ExperimentSettings.prototype.setConcurrentUpdatesEnabled = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ExperimentSettings} returns this
 */
proto.mesop.ExperimentSettings.prototype.clearConcurrentUpdatesEnabled = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ExperimentSettings.prototype.hasConcurrentUpdatesEnabled = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool websockets_enabled = 3;
 * @return {boolean}
 */
proto.mesop.ExperimentSettings.prototype.getWebsocketsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.ExperimentSettings} returns this
 */
proto.mesop.ExperimentSettings.prototype.setWebsocketsEnabled = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ExperimentSettings} returns this
 */
proto.mesop.ExperimentSettings.prototype.clearWebsocketsEnabled = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ExperimentSettings.prototype.hasWebsocketsEnabled = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mesop.UpdateStateEvent.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.mesop.UpdateStateEvent.TypeCase = {
  TYPE_NOT_SET: 0,
  FULL_STATES: 1,
  DIFF_STATES: 2
};

/**
 * @return {proto.mesop.UpdateStateEvent.TypeCase}
 */
proto.mesop.UpdateStateEvent.prototype.getTypeCase = function() {
  return /** @type {proto.mesop.UpdateStateEvent.TypeCase} */(jspb.Message.computeOneofCase(this, proto.mesop.UpdateStateEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.UpdateStateEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.UpdateStateEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.UpdateStateEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UpdateStateEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    fullStates: (f = msg.getFullStates()) && proto.mesop.States.toObject(includeInstance, f),
    diffStates: (f = msg.getDiffStates()) && proto.mesop.States.toObject(includeInstance, f),
    stateToken: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.UpdateStateEvent}
 */
proto.mesop.UpdateStateEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.UpdateStateEvent;
  return proto.mesop.UpdateStateEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.UpdateStateEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.UpdateStateEvent}
 */
proto.mesop.UpdateStateEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.States;
      reader.readMessage(value,proto.mesop.States.deserializeBinaryFromReader);
      msg.setFullStates(value);
      break;
    case 2:
      var value = new proto.mesop.States;
      reader.readMessage(value,proto.mesop.States.deserializeBinaryFromReader);
      msg.setDiffStates(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.UpdateStateEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.UpdateStateEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.UpdateStateEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UpdateStateEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFullStates();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.States.serializeBinaryToWriter
    );
  }
  f = message.getDiffStates();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.States.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional States full_states = 1;
 * @return {?proto.mesop.States}
 */
proto.mesop.UpdateStateEvent.prototype.getFullStates = function() {
  return /** @type{?proto.mesop.States} */ (
    jspb.Message.getWrapperField(this, proto.mesop.States, 1));
};


/**
 * @param {?proto.mesop.States|undefined} value
 * @return {!proto.mesop.UpdateStateEvent} returns this
*/
proto.mesop.UpdateStateEvent.prototype.setFullStates = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.mesop.UpdateStateEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UpdateStateEvent} returns this
 */
proto.mesop.UpdateStateEvent.prototype.clearFullStates = function() {
  return this.setFullStates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UpdateStateEvent.prototype.hasFullStates = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional States diff_states = 2;
 * @return {?proto.mesop.States}
 */
proto.mesop.UpdateStateEvent.prototype.getDiffStates = function() {
  return /** @type{?proto.mesop.States} */ (
    jspb.Message.getWrapperField(this, proto.mesop.States, 2));
};


/**
 * @param {?proto.mesop.States|undefined} value
 * @return {!proto.mesop.UpdateStateEvent} returns this
*/
proto.mesop.UpdateStateEvent.prototype.setDiffStates = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.mesop.UpdateStateEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UpdateStateEvent} returns this
 */
proto.mesop.UpdateStateEvent.prototype.clearDiffStates = function() {
  return this.setDiffStates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UpdateStateEvent.prototype.hasDiffStates = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string state_token = 4;
 * @return {string}
 */
proto.mesop.UpdateStateEvent.prototype.getStateToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.UpdateStateEvent} returns this
 */
proto.mesop.UpdateStateEvent.prototype.setStateToken = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.UpdateStateEvent} returns this
 */
proto.mesop.UpdateStateEvent.prototype.clearStateToken = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UpdateStateEvent.prototype.hasStateToken = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mesop.Command.oneofGroups_ = [[1,6,2,3,4,5,7]];

/**
 * @enum {number}
 */
proto.mesop.Command.CommandCase = {
  COMMAND_NOT_SET: 0,
  NAVIGATE: 1,
  UPDATE_QUERY_PARAM: 6,
  SCROLL_INTO_VIEW: 2,
  SET_THEME_MODE: 3,
  SET_THEME_DENSITY: 4,
  FOCUS_COMPONENT: 5,
  SET_PAGE_TITLE: 7
};

/**
 * @return {proto.mesop.Command.CommandCase}
 */
proto.mesop.Command.prototype.getCommandCase = function() {
  return /** @type {proto.mesop.Command.CommandCase} */(jspb.Message.computeOneofCase(this, proto.mesop.Command.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.Command.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.Command.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.Command} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Command.toObject = function(includeInstance, msg) {
  var f, obj = {
    navigate: (f = msg.getNavigate()) && proto.mesop.NavigateCommand.toObject(includeInstance, f),
    updateQueryParam: (f = msg.getUpdateQueryParam()) && proto.mesop.UpdateQueryParam.toObject(includeInstance, f),
    scrollIntoView: (f = msg.getScrollIntoView()) && proto.mesop.ScrollIntoViewCommand.toObject(includeInstance, f),
    setThemeMode: (f = msg.getSetThemeMode()) && proto.mesop.SetThemeMode.toObject(includeInstance, f),
    setThemeDensity: (f = msg.getSetThemeDensity()) && proto.mesop.SetThemeDensity.toObject(includeInstance, f),
    focusComponent: (f = msg.getFocusComponent()) && proto.mesop.FocusComponentCommand.toObject(includeInstance, f),
    setPageTitle: (f = msg.getSetPageTitle()) && proto.mesop.SetPageTitle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.Command}
 */
proto.mesop.Command.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.Command;
  return proto.mesop.Command.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.Command} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.Command}
 */
proto.mesop.Command.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.NavigateCommand;
      reader.readMessage(value,proto.mesop.NavigateCommand.deserializeBinaryFromReader);
      msg.setNavigate(value);
      break;
    case 6:
      var value = new proto.mesop.UpdateQueryParam;
      reader.readMessage(value,proto.mesop.UpdateQueryParam.deserializeBinaryFromReader);
      msg.setUpdateQueryParam(value);
      break;
    case 2:
      var value = new proto.mesop.ScrollIntoViewCommand;
      reader.readMessage(value,proto.mesop.ScrollIntoViewCommand.deserializeBinaryFromReader);
      msg.setScrollIntoView(value);
      break;
    case 3:
      var value = new proto.mesop.SetThemeMode;
      reader.readMessage(value,proto.mesop.SetThemeMode.deserializeBinaryFromReader);
      msg.setSetThemeMode(value);
      break;
    case 4:
      var value = new proto.mesop.SetThemeDensity;
      reader.readMessage(value,proto.mesop.SetThemeDensity.deserializeBinaryFromReader);
      msg.setSetThemeDensity(value);
      break;
    case 5:
      var value = new proto.mesop.FocusComponentCommand;
      reader.readMessage(value,proto.mesop.FocusComponentCommand.deserializeBinaryFromReader);
      msg.setFocusComponent(value);
      break;
    case 7:
      var value = new proto.mesop.SetPageTitle;
      reader.readMessage(value,proto.mesop.SetPageTitle.deserializeBinaryFromReader);
      msg.setSetPageTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.Command.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.Command.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.Command} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Command.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNavigate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.NavigateCommand.serializeBinaryToWriter
    );
  }
  f = message.getUpdateQueryParam();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.mesop.UpdateQueryParam.serializeBinaryToWriter
    );
  }
  f = message.getScrollIntoView();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.ScrollIntoViewCommand.serializeBinaryToWriter
    );
  }
  f = message.getSetThemeMode();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mesop.SetThemeMode.serializeBinaryToWriter
    );
  }
  f = message.getSetThemeDensity();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mesop.SetThemeDensity.serializeBinaryToWriter
    );
  }
  f = message.getFocusComponent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mesop.FocusComponentCommand.serializeBinaryToWriter
    );
  }
  f = message.getSetPageTitle();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mesop.SetPageTitle.serializeBinaryToWriter
    );
  }
};


/**
 * optional NavigateCommand navigate = 1;
 * @return {?proto.mesop.NavigateCommand}
 */
proto.mesop.Command.prototype.getNavigate = function() {
  return /** @type{?proto.mesop.NavigateCommand} */ (
    jspb.Message.getWrapperField(this, proto.mesop.NavigateCommand, 1));
};


/**
 * @param {?proto.mesop.NavigateCommand|undefined} value
 * @return {!proto.mesop.Command} returns this
*/
proto.mesop.Command.prototype.setNavigate = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.mesop.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Command} returns this
 */
proto.mesop.Command.prototype.clearNavigate = function() {
  return this.setNavigate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Command.prototype.hasNavigate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UpdateQueryParam update_query_param = 6;
 * @return {?proto.mesop.UpdateQueryParam}
 */
proto.mesop.Command.prototype.getUpdateQueryParam = function() {
  return /** @type{?proto.mesop.UpdateQueryParam} */ (
    jspb.Message.getWrapperField(this, proto.mesop.UpdateQueryParam, 6));
};


/**
 * @param {?proto.mesop.UpdateQueryParam|undefined} value
 * @return {!proto.mesop.Command} returns this
*/
proto.mesop.Command.prototype.setUpdateQueryParam = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.mesop.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Command} returns this
 */
proto.mesop.Command.prototype.clearUpdateQueryParam = function() {
  return this.setUpdateQueryParam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Command.prototype.hasUpdateQueryParam = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ScrollIntoViewCommand scroll_into_view = 2;
 * @return {?proto.mesop.ScrollIntoViewCommand}
 */
proto.mesop.Command.prototype.getScrollIntoView = function() {
  return /** @type{?proto.mesop.ScrollIntoViewCommand} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ScrollIntoViewCommand, 2));
};


/**
 * @param {?proto.mesop.ScrollIntoViewCommand|undefined} value
 * @return {!proto.mesop.Command} returns this
*/
proto.mesop.Command.prototype.setScrollIntoView = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.mesop.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Command} returns this
 */
proto.mesop.Command.prototype.clearScrollIntoView = function() {
  return this.setScrollIntoView(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Command.prototype.hasScrollIntoView = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SetThemeMode set_theme_mode = 3;
 * @return {?proto.mesop.SetThemeMode}
 */
proto.mesop.Command.prototype.getSetThemeMode = function() {
  return /** @type{?proto.mesop.SetThemeMode} */ (
    jspb.Message.getWrapperField(this, proto.mesop.SetThemeMode, 3));
};


/**
 * @param {?proto.mesop.SetThemeMode|undefined} value
 * @return {!proto.mesop.Command} returns this
*/
proto.mesop.Command.prototype.setSetThemeMode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.mesop.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Command} returns this
 */
proto.mesop.Command.prototype.clearSetThemeMode = function() {
  return this.setSetThemeMode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Command.prototype.hasSetThemeMode = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SetThemeDensity set_theme_density = 4;
 * @return {?proto.mesop.SetThemeDensity}
 */
proto.mesop.Command.prototype.getSetThemeDensity = function() {
  return /** @type{?proto.mesop.SetThemeDensity} */ (
    jspb.Message.getWrapperField(this, proto.mesop.SetThemeDensity, 4));
};


/**
 * @param {?proto.mesop.SetThemeDensity|undefined} value
 * @return {!proto.mesop.Command} returns this
*/
proto.mesop.Command.prototype.setSetThemeDensity = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.mesop.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Command} returns this
 */
proto.mesop.Command.prototype.clearSetThemeDensity = function() {
  return this.setSetThemeDensity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Command.prototype.hasSetThemeDensity = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FocusComponentCommand focus_component = 5;
 * @return {?proto.mesop.FocusComponentCommand}
 */
proto.mesop.Command.prototype.getFocusComponent = function() {
  return /** @type{?proto.mesop.FocusComponentCommand} */ (
    jspb.Message.getWrapperField(this, proto.mesop.FocusComponentCommand, 5));
};


/**
 * @param {?proto.mesop.FocusComponentCommand|undefined} value
 * @return {!proto.mesop.Command} returns this
*/
proto.mesop.Command.prototype.setFocusComponent = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.mesop.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Command} returns this
 */
proto.mesop.Command.prototype.clearFocusComponent = function() {
  return this.setFocusComponent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Command.prototype.hasFocusComponent = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SetPageTitle set_page_title = 7;
 * @return {?proto.mesop.SetPageTitle}
 */
proto.mesop.Command.prototype.getSetPageTitle = function() {
  return /** @type{?proto.mesop.SetPageTitle} */ (
    jspb.Message.getWrapperField(this, proto.mesop.SetPageTitle, 7));
};


/**
 * @param {?proto.mesop.SetPageTitle|undefined} value
 * @return {!proto.mesop.Command} returns this
*/
proto.mesop.Command.prototype.setSetPageTitle = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.mesop.Command.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Command} returns this
 */
proto.mesop.Command.prototype.clearSetPageTitle = function() {
  return this.setSetPageTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Command.prototype.hasSetPageTitle = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.NavigateCommand.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.NavigateCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.NavigateCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.NavigateCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.NavigateCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    queryParamsList: jspb.Message.toObjectList(msg.getQueryParamsList(),
    proto.mesop.QueryParam.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.NavigateCommand}
 */
proto.mesop.NavigateCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.NavigateCommand;
  return proto.mesop.NavigateCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.NavigateCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.NavigateCommand}
 */
proto.mesop.NavigateCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = new proto.mesop.QueryParam;
      reader.readMessage(value,proto.mesop.QueryParam.deserializeBinaryFromReader);
      msg.addQueryParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.NavigateCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.NavigateCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.NavigateCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.NavigateCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQueryParamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mesop.QueryParam.serializeBinaryToWriter
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.mesop.NavigateCommand.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.NavigateCommand} returns this
 */
proto.mesop.NavigateCommand.prototype.setUrl = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.NavigateCommand} returns this
 */
proto.mesop.NavigateCommand.prototype.clearUrl = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.NavigateCommand.prototype.hasUrl = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated QueryParam query_params = 2;
 * @return {!Array<!proto.mesop.QueryParam>}
 */
proto.mesop.NavigateCommand.prototype.getQueryParamsList = function() {
  return /** @type{!Array<!proto.mesop.QueryParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.QueryParam, 2));
};


/**
 * @param {!Array<!proto.mesop.QueryParam>} value
 * @return {!proto.mesop.NavigateCommand} returns this
*/
proto.mesop.NavigateCommand.prototype.setQueryParamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mesop.QueryParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.QueryParam}
 */
proto.mesop.NavigateCommand.prototype.addQueryParams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mesop.QueryParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.NavigateCommand} returns this
 */
proto.mesop.NavigateCommand.prototype.clearQueryParamsList = function() {
  return this.setQueryParamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.UpdateQueryParam.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.UpdateQueryParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.UpdateQueryParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UpdateQueryParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryParam: (f = msg.getQueryParam()) && proto.mesop.QueryParam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.UpdateQueryParam}
 */
proto.mesop.UpdateQueryParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.UpdateQueryParam;
  return proto.mesop.UpdateQueryParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.UpdateQueryParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.UpdateQueryParam}
 */
proto.mesop.UpdateQueryParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.QueryParam;
      reader.readMessage(value,proto.mesop.QueryParam.deserializeBinaryFromReader);
      msg.setQueryParam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.UpdateQueryParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.UpdateQueryParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.UpdateQueryParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UpdateQueryParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryParam();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.QueryParam.serializeBinaryToWriter
    );
  }
};


/**
 * optional QueryParam query_param = 1;
 * @return {?proto.mesop.QueryParam}
 */
proto.mesop.UpdateQueryParam.prototype.getQueryParam = function() {
  return /** @type{?proto.mesop.QueryParam} */ (
    jspb.Message.getWrapperField(this, proto.mesop.QueryParam, 1));
};


/**
 * @param {?proto.mesop.QueryParam|undefined} value
 * @return {!proto.mesop.UpdateQueryParam} returns this
*/
proto.mesop.UpdateQueryParam.prototype.setQueryParam = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UpdateQueryParam} returns this
 */
proto.mesop.UpdateQueryParam.prototype.clearQueryParam = function() {
  return this.setQueryParam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UpdateQueryParam.prototype.hasQueryParam = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ScrollIntoViewCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ScrollIntoViewCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ScrollIntoViewCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ScrollIntoViewCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ScrollIntoViewCommand}
 */
proto.mesop.ScrollIntoViewCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ScrollIntoViewCommand;
  return proto.mesop.ScrollIntoViewCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ScrollIntoViewCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ScrollIntoViewCommand}
 */
proto.mesop.ScrollIntoViewCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ScrollIntoViewCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ScrollIntoViewCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ScrollIntoViewCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ScrollIntoViewCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.mesop.ScrollIntoViewCommand.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.ScrollIntoViewCommand} returns this
 */
proto.mesop.ScrollIntoViewCommand.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ScrollIntoViewCommand} returns this
 */
proto.mesop.ScrollIntoViewCommand.prototype.clearKey = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ScrollIntoViewCommand.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.FocusComponentCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.FocusComponentCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.FocusComponentCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.FocusComponentCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.FocusComponentCommand}
 */
proto.mesop.FocusComponentCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.FocusComponentCommand;
  return proto.mesop.FocusComponentCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.FocusComponentCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.FocusComponentCommand}
 */
proto.mesop.FocusComponentCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.FocusComponentCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.FocusComponentCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.FocusComponentCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.FocusComponentCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.mesop.FocusComponentCommand.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.FocusComponentCommand} returns this
 */
proto.mesop.FocusComponentCommand.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.FocusComponentCommand} returns this
 */
proto.mesop.FocusComponentCommand.prototype.clearKey = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.FocusComponentCommand.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.SetPageTitle.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.SetPageTitle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.SetPageTitle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.SetPageTitle.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.SetPageTitle}
 */
proto.mesop.SetPageTitle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.SetPageTitle;
  return proto.mesop.SetPageTitle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.SetPageTitle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.SetPageTitle}
 */
proto.mesop.SetPageTitle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.SetPageTitle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.SetPageTitle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.SetPageTitle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.SetPageTitle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.mesop.SetPageTitle.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.SetPageTitle} returns this
 */
proto.mesop.SetPageTitle.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.SetPageTitle} returns this
 */
proto.mesop.SetPageTitle.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.SetPageTitle.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.SetThemeMode.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.SetThemeMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.SetThemeMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.SetThemeMode.toObject = function(includeInstance, msg) {
  var f, obj = {
    themeMode: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.SetThemeMode}
 */
proto.mesop.SetThemeMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.SetThemeMode;
  return proto.mesop.SetThemeMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.SetThemeMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.SetThemeMode}
 */
proto.mesop.SetThemeMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mesop.ThemeMode} */ (reader.readEnum());
      msg.setThemeMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.SetThemeMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.SetThemeMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.SetThemeMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.SetThemeMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!proto.mesop.ThemeMode} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ThemeMode theme_mode = 1;
 * @return {!proto.mesop.ThemeMode}
 */
proto.mesop.SetThemeMode.prototype.getThemeMode = function() {
  return /** @type {!proto.mesop.ThemeMode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mesop.ThemeMode} value
 * @return {!proto.mesop.SetThemeMode} returns this
 */
proto.mesop.SetThemeMode.prototype.setThemeMode = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.SetThemeMode} returns this
 */
proto.mesop.SetThemeMode.prototype.clearThemeMode = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.SetThemeMode.prototype.hasThemeMode = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.SetThemeDensity.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.SetThemeDensity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.SetThemeDensity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.SetThemeDensity.toObject = function(includeInstance, msg) {
  var f, obj = {
    density: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.SetThemeDensity}
 */
proto.mesop.SetThemeDensity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.SetThemeDensity;
  return proto.mesop.SetThemeDensity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.SetThemeDensity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.SetThemeDensity}
 */
proto.mesop.SetThemeDensity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDensity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.SetThemeDensity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.SetThemeDensity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.SetThemeDensity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.SetThemeDensity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 density = 1;
 * @return {number}
 */
proto.mesop.SetThemeDensity.prototype.getDensity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.SetThemeDensity} returns this
 */
proto.mesop.SetThemeDensity.prototype.setDensity = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.SetThemeDensity} returns this
 */
proto.mesop.SetThemeDensity.prototype.clearDensity = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.SetThemeDensity.prototype.hasDensity = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.States.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.States.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.States.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.States} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.States.toObject = function(includeInstance, msg) {
  var f, obj = {
    statesList: jspb.Message.toObjectList(msg.getStatesList(),
    proto.mesop.State.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.States}
 */
proto.mesop.States.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.States;
  return proto.mesop.States.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.States} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.States}
 */
proto.mesop.States.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.State;
      reader.readMessage(value,proto.mesop.State.deserializeBinaryFromReader);
      msg.addStates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.States.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.States.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.States} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.States.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mesop.State.serializeBinaryToWriter
    );
  }
};


/**
 * repeated State states = 1;
 * @return {!Array<!proto.mesop.State>}
 */
proto.mesop.States.prototype.getStatesList = function() {
  return /** @type{!Array<!proto.mesop.State>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.State, 1));
};


/**
 * @param {!Array<!proto.mesop.State>} value
 * @return {!proto.mesop.States} returns this
*/
proto.mesop.States.prototype.setStatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mesop.State=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.State}
 */
proto.mesop.States.prototype.addStates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mesop.State, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.States} returns this
 */
proto.mesop.States.prototype.clearStatesList = function() {
  return this.setStatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.State.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.State.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.State} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.State.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.State}
 */
proto.mesop.State.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.State;
  return proto.mesop.State.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.State} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.State}
 */
proto.mesop.State.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.State.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.State.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.State} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.State.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.mesop.State.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.State} returns this
 */
proto.mesop.State.prototype.setData = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.State} returns this
 */
proto.mesop.State.prototype.clearData = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.State.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.Component.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.Component.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.Component.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.Component} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Component.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = msg.getKey()) && proto.mesop.Key.toObject(includeInstance, f),
    style: (f = msg.getStyle()) && proto.mesop.Style.toObject(includeInstance, f),
    styleDebugJson: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    type: (f = msg.getType()) && proto.mesop.Type.toObject(includeInstance, f),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.mesop.Component.toObject, includeInstance),
    sourceCodeLocation: (f = msg.getSourceCodeLocation()) && proto.mesop.SourceCodeLocation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.Component}
 */
proto.mesop.Component.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.Component;
  return proto.mesop.Component.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.Component} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.Component}
 */
proto.mesop.Component.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.mesop.Key;
      reader.readMessage(value,proto.mesop.Key.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 5:
      var value = new proto.mesop.Style;
      reader.readMessage(value,proto.mesop.Style.deserializeBinaryFromReader);
      msg.setStyle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStyleDebugJson(value);
      break;
    case 1:
      var value = new proto.mesop.Type;
      reader.readMessage(value,proto.mesop.Type.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 2:
      var value = new proto.mesop.Component;
      reader.readMessage(value,proto.mesop.Component.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    case 4:
      var value = new proto.mesop.SourceCodeLocation;
      reader.readMessage(value,proto.mesop.SourceCodeLocation.deserializeBinaryFromReader);
      msg.setSourceCodeLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.Component.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.Component.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.Component} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Component.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mesop.Key.serializeBinaryToWriter
    );
  }
  f = message.getStyle();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mesop.Style.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.Type.serializeBinaryToWriter
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mesop.Component.serializeBinaryToWriter
    );
  }
  f = message.getSourceCodeLocation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mesop.SourceCodeLocation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Key key = 3;
 * @return {?proto.mesop.Key}
 */
proto.mesop.Component.prototype.getKey = function() {
  return /** @type{?proto.mesop.Key} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Key, 3));
};


/**
 * @param {?proto.mesop.Key|undefined} value
 * @return {!proto.mesop.Component} returns this
*/
proto.mesop.Component.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Component} returns this
 */
proto.mesop.Component.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Component.prototype.hasKey = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Style style = 5;
 * @return {?proto.mesop.Style}
 */
proto.mesop.Component.prototype.getStyle = function() {
  return /** @type{?proto.mesop.Style} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Style, 5));
};


/**
 * @param {?proto.mesop.Style|undefined} value
 * @return {!proto.mesop.Component} returns this
*/
proto.mesop.Component.prototype.setStyle = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Component} returns this
 */
proto.mesop.Component.prototype.clearStyle = function() {
  return this.setStyle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Component.prototype.hasStyle = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string style_debug_json = 6;
 * @return {string}
 */
proto.mesop.Component.prototype.getStyleDebugJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Component} returns this
 */
proto.mesop.Component.prototype.setStyleDebugJson = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Component} returns this
 */
proto.mesop.Component.prototype.clearStyleDebugJson = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Component.prototype.hasStyleDebugJson = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Type type = 1;
 * @return {?proto.mesop.Type}
 */
proto.mesop.Component.prototype.getType = function() {
  return /** @type{?proto.mesop.Type} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Type, 1));
};


/**
 * @param {?proto.mesop.Type|undefined} value
 * @return {!proto.mesop.Component} returns this
*/
proto.mesop.Component.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Component} returns this
 */
proto.mesop.Component.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Component.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Component children = 2;
 * @return {!Array<!proto.mesop.Component>}
 */
proto.mesop.Component.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.mesop.Component>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.Component, 2));
};


/**
 * @param {!Array<!proto.mesop.Component>} value
 * @return {!proto.mesop.Component} returns this
*/
proto.mesop.Component.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mesop.Component=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.Component}
 */
proto.mesop.Component.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mesop.Component, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.Component} returns this
 */
proto.mesop.Component.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};


/**
 * optional SourceCodeLocation source_code_location = 4;
 * @return {?proto.mesop.SourceCodeLocation}
 */
proto.mesop.Component.prototype.getSourceCodeLocation = function() {
  return /** @type{?proto.mesop.SourceCodeLocation} */ (
    jspb.Message.getWrapperField(this, proto.mesop.SourceCodeLocation, 4));
};


/**
 * @param {?proto.mesop.SourceCodeLocation|undefined} value
 * @return {!proto.mesop.Component} returns this
*/
proto.mesop.Component.prototype.setSourceCodeLocation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Component} returns this
 */
proto.mesop.Component.prototype.clearSourceCodeLocation = function() {
  return this.setSourceCodeLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Component.prototype.hasSourceCodeLocation = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.ComponentDiff.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ComponentDiff.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ComponentDiff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ComponentDiff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ComponentDiff.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    key: (f = msg.getKey()) && proto.mesop.Key.toObject(includeInstance, f),
    sourceCodeLocation: (f = msg.getSourceCodeLocation()) && proto.mesop.SourceCodeLocation.toObject(includeInstance, f),
    style: (f = msg.getStyle()) && proto.mesop.Style.toObject(includeInstance, f),
    styleDebugJson: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    type: (f = msg.getType()) && proto.mesop.Type.toObject(includeInstance, f),
    childrenList: jspb.Message.toObjectList(msg.getChildrenList(),
    proto.mesop.ComponentDiff.toObject, includeInstance),
    component: (f = msg.getComponent()) && proto.mesop.Component.toObject(includeInstance, f),
    updateStrategyKey: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    updateStrategySourceCodeLocation: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    updateStrategyStyle: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
    updateStrategyStyleDebugJson: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
    updateStrategyType: (f = jspb.Message.getField(msg, 13)) == null ? undefined : f,
    diffType: (f = jspb.Message.getField(msg, 14)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ComponentDiff}
 */
proto.mesop.ComponentDiff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ComponentDiff;
  return proto.mesop.ComponentDiff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ComponentDiff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ComponentDiff}
 */
proto.mesop.ComponentDiff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = new proto.mesop.Key;
      reader.readMessage(value,proto.mesop.Key.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 3:
      var value = new proto.mesop.SourceCodeLocation;
      reader.readMessage(value,proto.mesop.SourceCodeLocation.deserializeBinaryFromReader);
      msg.setSourceCodeLocation(value);
      break;
    case 4:
      var value = new proto.mesop.Style;
      reader.readMessage(value,proto.mesop.Style.deserializeBinaryFromReader);
      msg.setStyle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStyleDebugJson(value);
      break;
    case 6:
      var value = new proto.mesop.Type;
      reader.readMessage(value,proto.mesop.Type.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    case 7:
      var value = new proto.mesop.ComponentDiff;
      reader.readMessage(value,proto.mesop.ComponentDiff.deserializeBinaryFromReader);
      msg.addChildren(value);
      break;
    case 8:
      var value = new proto.mesop.Component;
      reader.readMessage(value,proto.mesop.Component.deserializeBinaryFromReader);
      msg.setComponent(value);
      break;
    case 9:
      var value = /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (reader.readEnum());
      msg.setUpdateStrategyKey(value);
      break;
    case 10:
      var value = /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (reader.readEnum());
      msg.setUpdateStrategySourceCodeLocation(value);
      break;
    case 11:
      var value = /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (reader.readEnum());
      msg.setUpdateStrategyStyle(value);
      break;
    case 12:
      var value = /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (reader.readEnum());
      msg.setUpdateStrategyStyleDebugJson(value);
      break;
    case 13:
      var value = /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (reader.readEnum());
      msg.setUpdateStrategyType(value);
      break;
    case 14:
      var value = /** @type {!proto.mesop.ComponentDiff.DiffType} */ (reader.readEnum());
      msg.setDiffType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ComponentDiff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ComponentDiff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ComponentDiff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ComponentDiff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.Key.serializeBinaryToWriter
    );
  }
  f = message.getSourceCodeLocation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mesop.SourceCodeLocation.serializeBinaryToWriter
    );
  }
  f = message.getStyle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mesop.Style.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.mesop.Type.serializeBinaryToWriter
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.mesop.ComponentDiff.serializeBinaryToWriter
    );
  }
  f = message.getComponent();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.mesop.Component.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = /** @type {!proto.mesop.ComponentDiff.DiffType} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeEnum(
      14,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mesop.ComponentDiff.UpdateStrategy = {
  UPDATE_STRATEGY_NOOP: 0,
  UPDATE_STRATEGY_REPLACE: 1,
  UPDATE_STRATEGY_APPEND: 2
};

/**
 * @enum {number}
 */
proto.mesop.ComponentDiff.DiffType = {
  DIFF_TYPE_NONE: 0,
  DIFF_TYPE_ADD: 1,
  DIFF_TYPE_DELETE: 2,
  DIFF_TYPE_UPDATE: 3
};

/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.mesop.ComponentDiff.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.setIndex = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearIndex = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasIndex = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Key key = 2;
 * @return {?proto.mesop.Key}
 */
proto.mesop.ComponentDiff.prototype.getKey = function() {
  return /** @type{?proto.mesop.Key} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Key, 2));
};


/**
 * @param {?proto.mesop.Key|undefined} value
 * @return {!proto.mesop.ComponentDiff} returns this
*/
proto.mesop.ComponentDiff.prototype.setKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SourceCodeLocation source_code_location = 3;
 * @return {?proto.mesop.SourceCodeLocation}
 */
proto.mesop.ComponentDiff.prototype.getSourceCodeLocation = function() {
  return /** @type{?proto.mesop.SourceCodeLocation} */ (
    jspb.Message.getWrapperField(this, proto.mesop.SourceCodeLocation, 3));
};


/**
 * @param {?proto.mesop.SourceCodeLocation|undefined} value
 * @return {!proto.mesop.ComponentDiff} returns this
*/
proto.mesop.ComponentDiff.prototype.setSourceCodeLocation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearSourceCodeLocation = function() {
  return this.setSourceCodeLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasSourceCodeLocation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Style style = 4;
 * @return {?proto.mesop.Style}
 */
proto.mesop.ComponentDiff.prototype.getStyle = function() {
  return /** @type{?proto.mesop.Style} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Style, 4));
};


/**
 * @param {?proto.mesop.Style|undefined} value
 * @return {!proto.mesop.ComponentDiff} returns this
*/
proto.mesop.ComponentDiff.prototype.setStyle = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearStyle = function() {
  return this.setStyle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasStyle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string style_debug_json = 5;
 * @return {string}
 */
proto.mesop.ComponentDiff.prototype.getStyleDebugJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.setStyleDebugJson = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearStyleDebugJson = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasStyleDebugJson = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Type type = 6;
 * @return {?proto.mesop.Type}
 */
proto.mesop.ComponentDiff.prototype.getType = function() {
  return /** @type{?proto.mesop.Type} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Type, 6));
};


/**
 * @param {?proto.mesop.Type|undefined} value
 * @return {!proto.mesop.ComponentDiff} returns this
*/
proto.mesop.ComponentDiff.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasType = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated ComponentDiff children = 7;
 * @return {!Array<!proto.mesop.ComponentDiff>}
 */
proto.mesop.ComponentDiff.prototype.getChildrenList = function() {
  return /** @type{!Array<!proto.mesop.ComponentDiff>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.ComponentDiff, 7));
};


/**
 * @param {!Array<!proto.mesop.ComponentDiff>} value
 * @return {!proto.mesop.ComponentDiff} returns this
*/
proto.mesop.ComponentDiff.prototype.setChildrenList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.mesop.ComponentDiff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.ComponentDiff}
 */
proto.mesop.ComponentDiff.prototype.addChildren = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.mesop.ComponentDiff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};


/**
 * optional Component component = 8;
 * @return {?proto.mesop.Component}
 */
proto.mesop.ComponentDiff.prototype.getComponent = function() {
  return /** @type{?proto.mesop.Component} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Component, 8));
};


/**
 * @param {?proto.mesop.Component|undefined} value
 * @return {!proto.mesop.ComponentDiff} returns this
*/
proto.mesop.ComponentDiff.prototype.setComponent = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearComponent = function() {
  return this.setComponent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasComponent = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional UpdateStrategy update_strategy_key = 9;
 * @return {!proto.mesop.ComponentDiff.UpdateStrategy}
 */
proto.mesop.ComponentDiff.prototype.getUpdateStrategyKey = function() {
  return /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.mesop.ComponentDiff.UpdateStrategy} value
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.setUpdateStrategyKey = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearUpdateStrategyKey = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasUpdateStrategyKey = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional UpdateStrategy update_strategy_source_code_location = 10;
 * @return {!proto.mesop.ComponentDiff.UpdateStrategy}
 */
proto.mesop.ComponentDiff.prototype.getUpdateStrategySourceCodeLocation = function() {
  return /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.mesop.ComponentDiff.UpdateStrategy} value
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.setUpdateStrategySourceCodeLocation = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearUpdateStrategySourceCodeLocation = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasUpdateStrategySourceCodeLocation = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional UpdateStrategy update_strategy_style = 11;
 * @return {!proto.mesop.ComponentDiff.UpdateStrategy}
 */
proto.mesop.ComponentDiff.prototype.getUpdateStrategyStyle = function() {
  return /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.mesop.ComponentDiff.UpdateStrategy} value
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.setUpdateStrategyStyle = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearUpdateStrategyStyle = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasUpdateStrategyStyle = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional UpdateStrategy update_strategy_style_debug_json = 12;
 * @return {!proto.mesop.ComponentDiff.UpdateStrategy}
 */
proto.mesop.ComponentDiff.prototype.getUpdateStrategyStyleDebugJson = function() {
  return /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.mesop.ComponentDiff.UpdateStrategy} value
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.setUpdateStrategyStyleDebugJson = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearUpdateStrategyStyleDebugJson = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasUpdateStrategyStyleDebugJson = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional UpdateStrategy update_strategy_type = 13;
 * @return {!proto.mesop.ComponentDiff.UpdateStrategy}
 */
proto.mesop.ComponentDiff.prototype.getUpdateStrategyType = function() {
  return /** @type {!proto.mesop.ComponentDiff.UpdateStrategy} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.mesop.ComponentDiff.UpdateStrategy} value
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.setUpdateStrategyType = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearUpdateStrategyType = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasUpdateStrategyType = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional DiffType diff_type = 14;
 * @return {!proto.mesop.ComponentDiff.DiffType}
 */
proto.mesop.ComponentDiff.prototype.getDiffType = function() {
  return /** @type {!proto.mesop.ComponentDiff.DiffType} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.mesop.ComponentDiff.DiffType} value
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.setDiffType = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentDiff} returns this
 */
proto.mesop.ComponentDiff.prototype.clearDiffType = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentDiff.prototype.hasDiffType = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.SourceCodeLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.SourceCodeLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.SourceCodeLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.SourceCodeLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    module: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    line: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    col: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.SourceCodeLocation}
 */
proto.mesop.SourceCodeLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.SourceCodeLocation;
  return proto.mesop.SourceCodeLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.SourceCodeLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.SourceCodeLocation}
 */
proto.mesop.SourceCodeLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModule(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLine(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.SourceCodeLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.SourceCodeLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.SourceCodeLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.SourceCodeLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string module = 1;
 * @return {string}
 */
proto.mesop.SourceCodeLocation.prototype.getModule = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.SourceCodeLocation} returns this
 */
proto.mesop.SourceCodeLocation.prototype.setModule = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.SourceCodeLocation} returns this
 */
proto.mesop.SourceCodeLocation.prototype.clearModule = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.SourceCodeLocation.prototype.hasModule = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 line = 2;
 * @return {number}
 */
proto.mesop.SourceCodeLocation.prototype.getLine = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.SourceCodeLocation} returns this
 */
proto.mesop.SourceCodeLocation.prototype.setLine = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.SourceCodeLocation} returns this
 */
proto.mesop.SourceCodeLocation.prototype.clearLine = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.SourceCodeLocation.prototype.hasLine = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 col = 3;
 * @return {number}
 */
proto.mesop.SourceCodeLocation.prototype.getCol = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.SourceCodeLocation} returns this
 */
proto.mesop.SourceCodeLocation.prototype.setCol = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.SourceCodeLocation} returns this
 */
proto.mesop.SourceCodeLocation.prototype.clearCol = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.SourceCodeLocation.prototype.hasCol = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.Key.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.Key.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.Key} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Key.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.Key}
 */
proto.mesop.Key.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.Key;
  return proto.mesop.Key.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.Key} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.Key}
 */
proto.mesop.Key.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.Key.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.Key.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.Key} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Key.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.mesop.Key.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Key} returns this
 */
proto.mesop.Key.prototype.setKey = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Key} returns this
 */
proto.mesop.Key.prototype.clearKey = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Key.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.Type.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.Type.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.Type} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Type.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && proto.mesop.ComponentName.toObject(includeInstance, f),
    value: msg.getValue_asB64(),
    debugJson: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    typeIndex: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.Type}
 */
proto.mesop.Type.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.Type;
  return proto.mesop.Type.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.Type} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.Type}
 */
proto.mesop.Type.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = new proto.mesop.ComponentName;
      reader.readMessage(value,proto.mesop.ComponentName.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebugJson(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTypeIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.Type.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.Type.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.Type} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Type.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mesop.ComponentName.serializeBinaryToWriter
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional ComponentName name = 4;
 * @return {?proto.mesop.ComponentName}
 */
proto.mesop.Type.prototype.getName = function() {
  return /** @type{?proto.mesop.ComponentName} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ComponentName, 4));
};


/**
 * @param {?proto.mesop.ComponentName|undefined} value
 * @return {!proto.mesop.Type} returns this
*/
proto.mesop.Type.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Type} returns this
 */
proto.mesop.Type.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Type.prototype.hasName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes value = 5;
 * @return {!(string|Uint8Array)}
 */
proto.mesop.Type.prototype.getValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes value = 5;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.mesop.Type.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.mesop.Type.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.mesop.Type} returns this
 */
proto.mesop.Type.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Type} returns this
 */
proto.mesop.Type.prototype.clearValue = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Type.prototype.hasValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string debug_json = 6;
 * @return {string}
 */
proto.mesop.Type.prototype.getDebugJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Type} returns this
 */
proto.mesop.Type.prototype.setDebugJson = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Type} returns this
 */
proto.mesop.Type.prototype.clearDebugJson = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Type.prototype.hasDebugJson = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional int32 type_index = 7;
 * @return {number}
 */
proto.mesop.Type.prototype.getTypeIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.Type} returns this
 */
proto.mesop.Type.prototype.setTypeIndex = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Type} returns this
 */
proto.mesop.Type.prototype.clearTypeIndex = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Type.prototype.hasTypeIndex = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.WebComponentType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.WebComponentType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.WebComponentType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.WebComponentType.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertiesJson: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    eventsJson: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.WebComponentType}
 */
proto.mesop.WebComponentType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.WebComponentType;
  return proto.mesop.WebComponentType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.WebComponentType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.WebComponentType}
 */
proto.mesop.WebComponentType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertiesJson(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventsJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.WebComponentType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.WebComponentType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.WebComponentType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.WebComponentType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string properties_json = 1;
 * @return {string}
 */
proto.mesop.WebComponentType.prototype.getPropertiesJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.WebComponentType} returns this
 */
proto.mesop.WebComponentType.prototype.setPropertiesJson = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.WebComponentType} returns this
 */
proto.mesop.WebComponentType.prototype.clearPropertiesJson = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.WebComponentType.prototype.hasPropertiesJson = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string events_json = 2;
 * @return {string}
 */
proto.mesop.WebComponentType.prototype.getEventsJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.WebComponentType} returns this
 */
proto.mesop.WebComponentType.prototype.setEventsJson = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.WebComponentType} returns this
 */
proto.mesop.WebComponentType.prototype.clearEventsJson = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.WebComponentType.prototype.hasEventsJson = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.UserDefinedType.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.UserDefinedType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.UserDefinedType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.UserDefinedType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UserDefinedType.toObject = function(includeInstance, msg) {
  var f, obj = {
    argsList: jspb.Message.toObjectList(msg.getArgsList(),
    proto.mesop.UserDefinedType.Arg.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.UserDefinedType}
 */
proto.mesop.UserDefinedType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.UserDefinedType;
  return proto.mesop.UserDefinedType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.UserDefinedType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.UserDefinedType}
 */
proto.mesop.UserDefinedType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.UserDefinedType.Arg;
      reader.readMessage(value,proto.mesop.UserDefinedType.Arg.deserializeBinaryFromReader);
      msg.addArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.UserDefinedType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.UserDefinedType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.UserDefinedType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UserDefinedType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArgsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mesop.UserDefinedType.Arg.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.UserDefinedType.Arg.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.UserDefinedType.Arg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.UserDefinedType.Arg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UserDefinedType.Arg.toObject = function(includeInstance, msg) {
  var f, obj = {
    argName: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    codeValue: (f = msg.getCodeValue()) && proto.mesop.CodeValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.UserDefinedType.Arg}
 */
proto.mesop.UserDefinedType.Arg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.UserDefinedType.Arg;
  return proto.mesop.UserDefinedType.Arg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.UserDefinedType.Arg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.UserDefinedType.Arg}
 */
proto.mesop.UserDefinedType.Arg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArgName(value);
      break;
    case 2:
      var value = new proto.mesop.CodeValue;
      reader.readMessage(value,proto.mesop.CodeValue.deserializeBinaryFromReader);
      msg.setCodeValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.UserDefinedType.Arg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.UserDefinedType.Arg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.UserDefinedType.Arg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.UserDefinedType.Arg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCodeValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.CodeValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string arg_name = 1;
 * @return {string}
 */
proto.mesop.UserDefinedType.Arg.prototype.getArgName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.UserDefinedType.Arg} returns this
 */
proto.mesop.UserDefinedType.Arg.prototype.setArgName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.UserDefinedType.Arg} returns this
 */
proto.mesop.UserDefinedType.Arg.prototype.clearArgName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserDefinedType.Arg.prototype.hasArgName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CodeValue code_value = 2;
 * @return {?proto.mesop.CodeValue}
 */
proto.mesop.UserDefinedType.Arg.prototype.getCodeValue = function() {
  return /** @type{?proto.mesop.CodeValue} */ (
    jspb.Message.getWrapperField(this, proto.mesop.CodeValue, 2));
};


/**
 * @param {?proto.mesop.CodeValue|undefined} value
 * @return {!proto.mesop.UserDefinedType.Arg} returns this
*/
proto.mesop.UserDefinedType.Arg.prototype.setCodeValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.UserDefinedType.Arg} returns this
 */
proto.mesop.UserDefinedType.Arg.prototype.clearCodeValue = function() {
  return this.setCodeValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.UserDefinedType.Arg.prototype.hasCodeValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Arg args = 1;
 * @return {!Array<!proto.mesop.UserDefinedType.Arg>}
 */
proto.mesop.UserDefinedType.prototype.getArgsList = function() {
  return /** @type{!Array<!proto.mesop.UserDefinedType.Arg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.UserDefinedType.Arg, 1));
};


/**
 * @param {!Array<!proto.mesop.UserDefinedType.Arg>} value
 * @return {!proto.mesop.UserDefinedType} returns this
*/
proto.mesop.UserDefinedType.prototype.setArgsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mesop.UserDefinedType.Arg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.UserDefinedType.Arg}
 */
proto.mesop.UserDefinedType.prototype.addArgs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mesop.UserDefinedType.Arg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.UserDefinedType} returns this
 */
proto.mesop.UserDefinedType.prototype.clearArgsList = function() {
  return this.setArgsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.Style.repeatedFields_ = [39];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.Style.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.Style.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.Style} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Style.toObject = function(includeInstance, msg) {
  var f, obj = {
    alignContent: (f = jspb.Message.getField(msg, 32)) == null ? undefined : f,
    alignItems: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    alignSelf: (f = jspb.Message.getField(msg, 63)) == null ? undefined : f,
    aspectRatio: (f = jspb.Message.getField(msg, 50)) == null ? undefined : f,
    backdropFilter: (f = jspb.Message.getField(msg, 73)) == null ? undefined : f,
    background: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    border: (f = msg.getBorder()) && proto.mesop.Border.toObject(includeInstance, f),
    borderRadius: (f = jspb.Message.getField(msg, 28)) == null ? undefined : f,
    bottom: (f = jspb.Message.getField(msg, 53)) == null ? undefined : f,
    boxShadow: (f = jspb.Message.getField(msg, 29)) == null ? undefined : f,
    boxSizing: (f = jspb.Message.getField(msg, 47)) == null ? undefined : f,
    color: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    columnGap: (f = jspb.Message.getField(msg, 33)) == null ? undefined : f,
    columns: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    cursor: (f = jspb.Message.getField(msg, 46)) == null ? undefined : f,
    display: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    flex: (f = jspb.Message.getField(msg, 74)) == null ? undefined : f,
    flexBasis: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    flexDirection: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    flexGrow: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    flexShrink: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    flexWrap: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
    fontFamily: (f = jspb.Message.getField(msg, 45)) == null ? undefined : f,
    fontSize: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
    fontStyle: (f = jspb.Message.getField(msg, 13)) == null ? undefined : f,
    fontWeight: (f = jspb.Message.getField(msg, 14)) == null ? undefined : f,
    gap: (f = jspb.Message.getField(msg, 30)) == null ? undefined : f,
    gridArea: (f = jspb.Message.getField(msg, 34)) == null ? undefined : f,
    gridAutoColumns: (f = jspb.Message.getField(msg, 57)) == null ? undefined : f,
    gridAutoFlow: (f = jspb.Message.getField(msg, 58)) == null ? undefined : f,
    gridAutoRows: (f = jspb.Message.getField(msg, 59)) == null ? undefined : f,
    gridColumn: (f = jspb.Message.getField(msg, 60)) == null ? undefined : f,
    gridColumnStart: (f = jspb.Message.getField(msg, 35)) == null ? undefined : f,
    gridColumnEnd: (f = jspb.Message.getField(msg, 36)) == null ? undefined : f,
    gridRow: (f = jspb.Message.getField(msg, 61)) == null ? undefined : f,
    gridRowStart: (f = jspb.Message.getField(msg, 37)) == null ? undefined : f,
    gridRowEnd: (f = jspb.Message.getField(msg, 38)) == null ? undefined : f,
    gridTemplateAreasList: (f = jspb.Message.getRepeatedField(msg, 39)) == null ? undefined : f,
    gridTemplateColumns: (f = jspb.Message.getField(msg, 40)) == null ? undefined : f,
    gridTemplateRows: (f = jspb.Message.getField(msg, 41)) == null ? undefined : f,
    height: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f,
    justifyContent: (f = jspb.Message.getField(msg, 16)) == null ? undefined : f,
    justifyItems: (f = jspb.Message.getField(msg, 42)) == null ? undefined : f,
    justifySelf: (f = jspb.Message.getField(msg, 64)) == null ? undefined : f,
    left: (f = jspb.Message.getField(msg, 54)) == null ? undefined : f,
    letterSpacing: (f = jspb.Message.getField(msg, 17)) == null ? undefined : f,
    lineHeight: (f = jspb.Message.getField(msg, 31)) == null ? undefined : f,
    margin: (f = msg.getMargin()) && proto.mesop.EdgeInsets.toObject(includeInstance, f),
    maxHeight: (f = jspb.Message.getField(msg, 67)) == null ? undefined : f,
    maxWidth: (f = jspb.Message.getField(msg, 68)) == null ? undefined : f,
    minHeight: (f = jspb.Message.getField(msg, 69)) == null ? undefined : f,
    minWidth: (f = jspb.Message.getField(msg, 70)) == null ? undefined : f,
    objectFit: (f = jspb.Message.getField(msg, 75)) == null ? undefined : f,
    opacity: (f = jspb.Message.getField(msg, 52)) == null ? undefined : f,
    outline: (f = jspb.Message.getField(msg, 44)) == null ? undefined : f,
    overflowWrap: (f = jspb.Message.getField(msg, 49)) == null ? undefined : f,
    overflow: (f = jspb.Message.getField(msg, 76)) == null ? undefined : f,
    overflowX: (f = jspb.Message.getField(msg, 19)) == null ? undefined : f,
    overflowY: (f = jspb.Message.getField(msg, 20)) == null ? undefined : f,
    padding: (f = msg.getPadding()) && proto.mesop.EdgeInsets.toObject(includeInstance, f),
    placeItems: (f = jspb.Message.getField(msg, 72)) == null ? undefined : f,
    pointerEvents: (f = jspb.Message.getField(msg, 71)) == null ? undefined : f,
    position: (f = jspb.Message.getField(msg, 22)) == null ? undefined : f,
    right: (f = jspb.Message.getField(msg, 55)) == null ? undefined : f,
    rotate: (f = jspb.Message.getField(msg, 66)) == null ? undefined : f,
    rowGap: (f = jspb.Message.getField(msg, 43)) == null ? undefined : f,
    textAlign: (f = jspb.Message.getField(msg, 23)) == null ? undefined : f,
    textDecoration: (f = jspb.Message.getField(msg, 24)) == null ? undefined : f,
    textOverflow: (f = jspb.Message.getField(msg, 25)) == null ? undefined : f,
    textShadow: (f = jspb.Message.getField(msg, 77)) == null ? undefined : f,
    textTransform: (f = jspb.Message.getField(msg, 78)) == null ? undefined : f,
    top: (f = jspb.Message.getField(msg, 56)) == null ? undefined : f,
    transform: (f = jspb.Message.getField(msg, 65)) == null ? undefined : f,
    transition: (f = jspb.Message.getField(msg, 79)) == null ? undefined : f,
    visibility: (f = jspb.Message.getField(msg, 51)) == null ? undefined : f,
    whiteSpace: (f = jspb.Message.getField(msg, 26)) == null ? undefined : f,
    width: (f = jspb.Message.getField(msg, 27)) == null ? undefined : f,
    wordWrap: (f = jspb.Message.getField(msg, 80)) == null ? undefined : f,
    verticalAlign: (f = jspb.Message.getField(msg, 81)) == null ? undefined : f,
    zIndex: (f = jspb.Message.getField(msg, 48)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.Style}
 */
proto.mesop.Style.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.Style;
  return proto.mesop.Style.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.Style} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.Style}
 */
proto.mesop.Style.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlignContent(value);
      break;
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlignItems(value);
      break;
    case 63:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlignSelf(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setAspectRatio(value);
      break;
    case 73:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackdropFilter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackground(value);
      break;
    case 3:
      var value = new proto.mesop.Border;
      reader.readMessage(value,proto.mesop.Border.deserializeBinaryFromReader);
      msg.setBorder(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setBorderRadius(value);
      break;
    case 53:
      var value = /** @type {string} */ (reader.readString());
      msg.setBottom(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setBoxShadow(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setBoxSizing(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumnGap(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setColumns(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setCursor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplay(value);
      break;
    case 74:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlex(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlexBasis(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlexDirection(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlexGrow(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlexShrink(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlexWrap(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setFontFamily(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFontSize(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFontStyle(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFontWeight(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setGap(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridArea(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridAutoColumns(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridAutoFlow(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridAutoRows(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridColumn(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridColumnStart(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridColumnEnd(value);
      break;
    case 61:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridRow(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridRowStart(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridRowEnd(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.addGridTemplateAreas(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridTemplateColumns(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setGridTemplateRows(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeight(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setJustifyContent(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setJustifyItems(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setJustifySelf(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeft(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLetterSpacing(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineHeight(value);
      break;
    case 18:
      var value = new proto.mesop.EdgeInsets;
      reader.readMessage(value,proto.mesop.EdgeInsets.deserializeBinaryFromReader);
      msg.setMargin(value);
      break;
    case 67:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxHeight(value);
      break;
    case 68:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxWidth(value);
      break;
    case 69:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinHeight(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setMinWidth(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectFit(value);
      break;
    case 52:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpacity(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutline(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverflowWrap(value);
      break;
    case 76:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverflow(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverflowX(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverflowY(value);
      break;
    case 21:
      var value = new proto.mesop.EdgeInsets;
      reader.readMessage(value,proto.mesop.EdgeInsets.deserializeBinaryFromReader);
      msg.setPadding(value);
      break;
    case 72:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceItems(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setPointerEvents(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosition(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setRight(value);
      break;
    case 66:
      var value = /** @type {string} */ (reader.readString());
      msg.setRotate(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setRowGap(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextAlign(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextDecoration(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextOverflow(value);
      break;
    case 77:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextShadow(value);
      break;
    case 78:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextTransform(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setTop(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransform(value);
      break;
    case 79:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransition(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setVisibility(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhiteSpace(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidth(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setWordWrap(value);
      break;
    case 81:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerticalAlign(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setZIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.Style.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.Style.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.Style} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Style.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 32));
  if (f != null) {
    writer.writeString(
      32,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 63));
  if (f != null) {
    writer.writeString(
      63,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 50));
  if (f != null) {
    writer.writeString(
      50,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 73));
  if (f != null) {
    writer.writeString(
      73,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBorder();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mesop.Border.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 28));
  if (f != null) {
    writer.writeString(
      28,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 53));
  if (f != null) {
    writer.writeString(
      53,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 29));
  if (f != null) {
    writer.writeString(
      29,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 47));
  if (f != null) {
    writer.writeString(
      47,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 33));
  if (f != null) {
    writer.writeString(
      33,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 46));
  if (f != null) {
    writer.writeString(
      46,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 74));
  if (f != null) {
    writer.writeString(
      74,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 45));
  if (f != null) {
    writer.writeString(
      45,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 30));
  if (f != null) {
    writer.writeString(
      30,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 34));
  if (f != null) {
    writer.writeString(
      34,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 57));
  if (f != null) {
    writer.writeString(
      57,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 58));
  if (f != null) {
    writer.writeString(
      58,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 59));
  if (f != null) {
    writer.writeString(
      59,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 60));
  if (f != null) {
    writer.writeString(
      60,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 35));
  if (f != null) {
    writer.writeString(
      35,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 36));
  if (f != null) {
    writer.writeString(
      36,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 61));
  if (f != null) {
    writer.writeString(
      61,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 37));
  if (f != null) {
    writer.writeString(
      37,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 38));
  if (f != null) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getGridTemplateAreasList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      39,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 40));
  if (f != null) {
    writer.writeString(
      40,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 41));
  if (f != null) {
    writer.writeString(
      41,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 42));
  if (f != null) {
    writer.writeString(
      42,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 64));
  if (f != null) {
    writer.writeString(
      64,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 54));
  if (f != null) {
    writer.writeString(
      54,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 31));
  if (f != null) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getMargin();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.mesop.EdgeInsets.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 67));
  if (f != null) {
    writer.writeString(
      67,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 68));
  if (f != null) {
    writer.writeString(
      68,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 69));
  if (f != null) {
    writer.writeString(
      69,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 70));
  if (f != null) {
    writer.writeString(
      70,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 75));
  if (f != null) {
    writer.writeString(
      75,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 52));
  if (f != null) {
    writer.writeString(
      52,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 44));
  if (f != null) {
    writer.writeString(
      44,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 49));
  if (f != null) {
    writer.writeString(
      49,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 76));
  if (f != null) {
    writer.writeString(
      76,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeString(
      19,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPadding();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.mesop.EdgeInsets.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 72));
  if (f != null) {
    writer.writeString(
      72,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 71));
  if (f != null) {
    writer.writeString(
      71,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeString(
      22,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 55));
  if (f != null) {
    writer.writeString(
      55,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 66));
  if (f != null) {
    writer.writeString(
      66,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 43));
  if (f != null) {
    writer.writeString(
      43,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeString(
      23,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 24));
  if (f != null) {
    writer.writeString(
      24,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeString(
      25,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 77));
  if (f != null) {
    writer.writeString(
      77,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 78));
  if (f != null) {
    writer.writeString(
      78,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 56));
  if (f != null) {
    writer.writeString(
      56,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 65));
  if (f != null) {
    writer.writeString(
      65,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 79));
  if (f != null) {
    writer.writeString(
      79,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 51));
  if (f != null) {
    writer.writeString(
      51,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeString(
      26,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 27));
  if (f != null) {
    writer.writeString(
      27,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 80));
  if (f != null) {
    writer.writeString(
      80,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 81));
  if (f != null) {
    writer.writeString(
      81,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 48));
  if (f != null) {
    writer.writeInt32(
      48,
      f
    );
  }
};


/**
 * optional string align_content = 32;
 * @return {string}
 */
proto.mesop.Style.prototype.getAlignContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setAlignContent = function(value) {
  return jspb.Message.setField(this, 32, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearAlignContent = function() {
  return jspb.Message.setField(this, 32, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasAlignContent = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional string align_items = 1;
 * @return {string}
 */
proto.mesop.Style.prototype.getAlignItems = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setAlignItems = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearAlignItems = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasAlignItems = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string align_self = 63;
 * @return {string}
 */
proto.mesop.Style.prototype.getAlignSelf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 63, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setAlignSelf = function(value) {
  return jspb.Message.setField(this, 63, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearAlignSelf = function() {
  return jspb.Message.setField(this, 63, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasAlignSelf = function() {
  return jspb.Message.getField(this, 63) != null;
};


/**
 * optional string aspect_ratio = 50;
 * @return {string}
 */
proto.mesop.Style.prototype.getAspectRatio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setAspectRatio = function(value) {
  return jspb.Message.setField(this, 50, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearAspectRatio = function() {
  return jspb.Message.setField(this, 50, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasAspectRatio = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional string backdrop_filter = 73;
 * @return {string}
 */
proto.mesop.Style.prototype.getBackdropFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 73, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setBackdropFilter = function(value) {
  return jspb.Message.setField(this, 73, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearBackdropFilter = function() {
  return jspb.Message.setField(this, 73, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasBackdropFilter = function() {
  return jspb.Message.getField(this, 73) != null;
};


/**
 * optional string background = 2;
 * @return {string}
 */
proto.mesop.Style.prototype.getBackground = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setBackground = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearBackground = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasBackground = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Border border = 3;
 * @return {?proto.mesop.Border}
 */
proto.mesop.Style.prototype.getBorder = function() {
  return /** @type{?proto.mesop.Border} */ (
    jspb.Message.getWrapperField(this, proto.mesop.Border, 3));
};


/**
 * @param {?proto.mesop.Border|undefined} value
 * @return {!proto.mesop.Style} returns this
*/
proto.mesop.Style.prototype.setBorder = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearBorder = function() {
  return this.setBorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasBorder = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string border_radius = 28;
 * @return {string}
 */
proto.mesop.Style.prototype.getBorderRadius = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setBorderRadius = function(value) {
  return jspb.Message.setField(this, 28, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearBorderRadius = function() {
  return jspb.Message.setField(this, 28, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasBorderRadius = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional string bottom = 53;
 * @return {string}
 */
proto.mesop.Style.prototype.getBottom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 53, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setBottom = function(value) {
  return jspb.Message.setField(this, 53, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearBottom = function() {
  return jspb.Message.setField(this, 53, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasBottom = function() {
  return jspb.Message.getField(this, 53) != null;
};


/**
 * optional string box_shadow = 29;
 * @return {string}
 */
proto.mesop.Style.prototype.getBoxShadow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setBoxShadow = function(value) {
  return jspb.Message.setField(this, 29, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearBoxShadow = function() {
  return jspb.Message.setField(this, 29, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasBoxShadow = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional string box_sizing = 47;
 * @return {string}
 */
proto.mesop.Style.prototype.getBoxSizing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setBoxSizing = function(value) {
  return jspb.Message.setField(this, 47, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearBoxSizing = function() {
  return jspb.Message.setField(this, 47, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasBoxSizing = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional string color = 4;
 * @return {string}
 */
proto.mesop.Style.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setColor = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearColor = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasColor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string column_gap = 33;
 * @return {string}
 */
proto.mesop.Style.prototype.getColumnGap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setColumnGap = function(value) {
  return jspb.Message.setField(this, 33, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearColumnGap = function() {
  return jspb.Message.setField(this, 33, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasColumnGap = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional string columns = 5;
 * @return {string}
 */
proto.mesop.Style.prototype.getColumns = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setColumns = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearColumns = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasColumns = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string cursor = 46;
 * @return {string}
 */
proto.mesop.Style.prototype.getCursor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setCursor = function(value) {
  return jspb.Message.setField(this, 46, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearCursor = function() {
  return jspb.Message.setField(this, 46, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasCursor = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional string display = 6;
 * @return {string}
 */
proto.mesop.Style.prototype.getDisplay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setDisplay = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearDisplay = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasDisplay = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string flex = 74;
 * @return {string}
 */
proto.mesop.Style.prototype.getFlex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 74, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setFlex = function(value) {
  return jspb.Message.setField(this, 74, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearFlex = function() {
  return jspb.Message.setField(this, 74, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasFlex = function() {
  return jspb.Message.getField(this, 74) != null;
};


/**
 * optional string flex_basis = 7;
 * @return {string}
 */
proto.mesop.Style.prototype.getFlexBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setFlexBasis = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearFlexBasis = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasFlexBasis = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string flex_direction = 8;
 * @return {string}
 */
proto.mesop.Style.prototype.getFlexDirection = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setFlexDirection = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearFlexDirection = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasFlexDirection = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 flex_grow = 9;
 * @return {number}
 */
proto.mesop.Style.prototype.getFlexGrow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setFlexGrow = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearFlexGrow = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasFlexGrow = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string flex_shrink = 10;
 * @return {string}
 */
proto.mesop.Style.prototype.getFlexShrink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setFlexShrink = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearFlexShrink = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasFlexShrink = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string flex_wrap = 11;
 * @return {string}
 */
proto.mesop.Style.prototype.getFlexWrap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setFlexWrap = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearFlexWrap = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasFlexWrap = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string font_family = 45;
 * @return {string}
 */
proto.mesop.Style.prototype.getFontFamily = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setFontFamily = function(value) {
  return jspb.Message.setField(this, 45, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearFontFamily = function() {
  return jspb.Message.setField(this, 45, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasFontFamily = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional string font_size = 12;
 * @return {string}
 */
proto.mesop.Style.prototype.getFontSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setFontSize = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearFontSize = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasFontSize = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string font_style = 13;
 * @return {string}
 */
proto.mesop.Style.prototype.getFontStyle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setFontStyle = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearFontStyle = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasFontStyle = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string font_weight = 14;
 * @return {string}
 */
proto.mesop.Style.prototype.getFontWeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setFontWeight = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearFontWeight = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasFontWeight = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string gap = 30;
 * @return {string}
 */
proto.mesop.Style.prototype.getGap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGap = function(value) {
  return jspb.Message.setField(this, 30, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGap = function() {
  return jspb.Message.setField(this, 30, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGap = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional string grid_area = 34;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridArea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridArea = function(value) {
  return jspb.Message.setField(this, 34, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridArea = function() {
  return jspb.Message.setField(this, 34, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridArea = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional string grid_auto_columns = 57;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridAutoColumns = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridAutoColumns = function(value) {
  return jspb.Message.setField(this, 57, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridAutoColumns = function() {
  return jspb.Message.setField(this, 57, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridAutoColumns = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional string grid_auto_flow = 58;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridAutoFlow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridAutoFlow = function(value) {
  return jspb.Message.setField(this, 58, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridAutoFlow = function() {
  return jspb.Message.setField(this, 58, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridAutoFlow = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional string grid_auto_rows = 59;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridAutoRows = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridAutoRows = function(value) {
  return jspb.Message.setField(this, 59, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridAutoRows = function() {
  return jspb.Message.setField(this, 59, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridAutoRows = function() {
  return jspb.Message.getField(this, 59) != null;
};


/**
 * optional string grid_column = 60;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridColumn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridColumn = function(value) {
  return jspb.Message.setField(this, 60, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridColumn = function() {
  return jspb.Message.setField(this, 60, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridColumn = function() {
  return jspb.Message.getField(this, 60) != null;
};


/**
 * optional string grid_column_start = 35;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridColumnStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridColumnStart = function(value) {
  return jspb.Message.setField(this, 35, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridColumnStart = function() {
  return jspb.Message.setField(this, 35, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridColumnStart = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional string grid_column_end = 36;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridColumnEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridColumnEnd = function(value) {
  return jspb.Message.setField(this, 36, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridColumnEnd = function() {
  return jspb.Message.setField(this, 36, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridColumnEnd = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional string grid_row = 61;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridRow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 61, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridRow = function(value) {
  return jspb.Message.setField(this, 61, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridRow = function() {
  return jspb.Message.setField(this, 61, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridRow = function() {
  return jspb.Message.getField(this, 61) != null;
};


/**
 * optional string grid_row_start = 37;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridRowStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridRowStart = function(value) {
  return jspb.Message.setField(this, 37, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridRowStart = function() {
  return jspb.Message.setField(this, 37, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridRowStart = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional string grid_row_end = 38;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridRowEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridRowEnd = function(value) {
  return jspb.Message.setField(this, 38, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridRowEnd = function() {
  return jspb.Message.setField(this, 38, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridRowEnd = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * repeated string grid_template_areas = 39;
 * @return {!Array<string>}
 */
proto.mesop.Style.prototype.getGridTemplateAreasList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 39));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridTemplateAreasList = function(value) {
  return jspb.Message.setField(this, 39, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.addGridTemplateAreas = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 39, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridTemplateAreasList = function() {
  return this.setGridTemplateAreasList([]);
};


/**
 * optional string grid_template_columns = 40;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridTemplateColumns = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridTemplateColumns = function(value) {
  return jspb.Message.setField(this, 40, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridTemplateColumns = function() {
  return jspb.Message.setField(this, 40, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridTemplateColumns = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional string grid_template_rows = 41;
 * @return {string}
 */
proto.mesop.Style.prototype.getGridTemplateRows = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setGridTemplateRows = function(value) {
  return jspb.Message.setField(this, 41, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearGridTemplateRows = function() {
  return jspb.Message.setField(this, 41, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasGridTemplateRows = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional string height = 15;
 * @return {string}
 */
proto.mesop.Style.prototype.getHeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setHeight = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearHeight = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasHeight = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string justify_content = 16;
 * @return {string}
 */
proto.mesop.Style.prototype.getJustifyContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setJustifyContent = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearJustifyContent = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasJustifyContent = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string justify_items = 42;
 * @return {string}
 */
proto.mesop.Style.prototype.getJustifyItems = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setJustifyItems = function(value) {
  return jspb.Message.setField(this, 42, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearJustifyItems = function() {
  return jspb.Message.setField(this, 42, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasJustifyItems = function() {
  return jspb.Message.getField(this, 42) != null;
};


/**
 * optional string justify_self = 64;
 * @return {string}
 */
proto.mesop.Style.prototype.getJustifySelf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setJustifySelf = function(value) {
  return jspb.Message.setField(this, 64, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearJustifySelf = function() {
  return jspb.Message.setField(this, 64, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasJustifySelf = function() {
  return jspb.Message.getField(this, 64) != null;
};


/**
 * optional string left = 54;
 * @return {string}
 */
proto.mesop.Style.prototype.getLeft = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setLeft = function(value) {
  return jspb.Message.setField(this, 54, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearLeft = function() {
  return jspb.Message.setField(this, 54, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasLeft = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional string letter_spacing = 17;
 * @return {string}
 */
proto.mesop.Style.prototype.getLetterSpacing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setLetterSpacing = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearLetterSpacing = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasLetterSpacing = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string line_height = 31;
 * @return {string}
 */
proto.mesop.Style.prototype.getLineHeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setLineHeight = function(value) {
  return jspb.Message.setField(this, 31, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearLineHeight = function() {
  return jspb.Message.setField(this, 31, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasLineHeight = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional EdgeInsets margin = 18;
 * @return {?proto.mesop.EdgeInsets}
 */
proto.mesop.Style.prototype.getMargin = function() {
  return /** @type{?proto.mesop.EdgeInsets} */ (
    jspb.Message.getWrapperField(this, proto.mesop.EdgeInsets, 18));
};


/**
 * @param {?proto.mesop.EdgeInsets|undefined} value
 * @return {!proto.mesop.Style} returns this
*/
proto.mesop.Style.prototype.setMargin = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearMargin = function() {
  return this.setMargin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasMargin = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string max_height = 67;
 * @return {string}
 */
proto.mesop.Style.prototype.getMaxHeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 67, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setMaxHeight = function(value) {
  return jspb.Message.setField(this, 67, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearMaxHeight = function() {
  return jspb.Message.setField(this, 67, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasMaxHeight = function() {
  return jspb.Message.getField(this, 67) != null;
};


/**
 * optional string max_width = 68;
 * @return {string}
 */
proto.mesop.Style.prototype.getMaxWidth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 68, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setMaxWidth = function(value) {
  return jspb.Message.setField(this, 68, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearMaxWidth = function() {
  return jspb.Message.setField(this, 68, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasMaxWidth = function() {
  return jspb.Message.getField(this, 68) != null;
};


/**
 * optional string min_height = 69;
 * @return {string}
 */
proto.mesop.Style.prototype.getMinHeight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 69, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setMinHeight = function(value) {
  return jspb.Message.setField(this, 69, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearMinHeight = function() {
  return jspb.Message.setField(this, 69, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasMinHeight = function() {
  return jspb.Message.getField(this, 69) != null;
};


/**
 * optional string min_width = 70;
 * @return {string}
 */
proto.mesop.Style.prototype.getMinWidth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setMinWidth = function(value) {
  return jspb.Message.setField(this, 70, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearMinWidth = function() {
  return jspb.Message.setField(this, 70, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasMinWidth = function() {
  return jspb.Message.getField(this, 70) != null;
};


/**
 * optional string object_fit = 75;
 * @return {string}
 */
proto.mesop.Style.prototype.getObjectFit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 75, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setObjectFit = function(value) {
  return jspb.Message.setField(this, 75, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearObjectFit = function() {
  return jspb.Message.setField(this, 75, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasObjectFit = function() {
  return jspb.Message.getField(this, 75) != null;
};


/**
 * optional string opacity = 52;
 * @return {string}
 */
proto.mesop.Style.prototype.getOpacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 52, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setOpacity = function(value) {
  return jspb.Message.setField(this, 52, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearOpacity = function() {
  return jspb.Message.setField(this, 52, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasOpacity = function() {
  return jspb.Message.getField(this, 52) != null;
};


/**
 * optional string outline = 44;
 * @return {string}
 */
proto.mesop.Style.prototype.getOutline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setOutline = function(value) {
  return jspb.Message.setField(this, 44, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearOutline = function() {
  return jspb.Message.setField(this, 44, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasOutline = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional string overflow_wrap = 49;
 * @return {string}
 */
proto.mesop.Style.prototype.getOverflowWrap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setOverflowWrap = function(value) {
  return jspb.Message.setField(this, 49, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearOverflowWrap = function() {
  return jspb.Message.setField(this, 49, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasOverflowWrap = function() {
  return jspb.Message.getField(this, 49) != null;
};


/**
 * optional string overflow = 76;
 * @return {string}
 */
proto.mesop.Style.prototype.getOverflow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 76, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setOverflow = function(value) {
  return jspb.Message.setField(this, 76, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearOverflow = function() {
  return jspb.Message.setField(this, 76, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasOverflow = function() {
  return jspb.Message.getField(this, 76) != null;
};


/**
 * optional string overflow_x = 19;
 * @return {string}
 */
proto.mesop.Style.prototype.getOverflowX = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setOverflowX = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearOverflowX = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasOverflowX = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string overflow_y = 20;
 * @return {string}
 */
proto.mesop.Style.prototype.getOverflowY = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setOverflowY = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearOverflowY = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasOverflowY = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional EdgeInsets padding = 21;
 * @return {?proto.mesop.EdgeInsets}
 */
proto.mesop.Style.prototype.getPadding = function() {
  return /** @type{?proto.mesop.EdgeInsets} */ (
    jspb.Message.getWrapperField(this, proto.mesop.EdgeInsets, 21));
};


/**
 * @param {?proto.mesop.EdgeInsets|undefined} value
 * @return {!proto.mesop.Style} returns this
*/
proto.mesop.Style.prototype.setPadding = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearPadding = function() {
  return this.setPadding(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasPadding = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string place_items = 72;
 * @return {string}
 */
proto.mesop.Style.prototype.getPlaceItems = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 72, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setPlaceItems = function(value) {
  return jspb.Message.setField(this, 72, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearPlaceItems = function() {
  return jspb.Message.setField(this, 72, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasPlaceItems = function() {
  return jspb.Message.getField(this, 72) != null;
};


/**
 * optional string pointer_events = 71;
 * @return {string}
 */
proto.mesop.Style.prototype.getPointerEvents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setPointerEvents = function(value) {
  return jspb.Message.setField(this, 71, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearPointerEvents = function() {
  return jspb.Message.setField(this, 71, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasPointerEvents = function() {
  return jspb.Message.getField(this, 71) != null;
};


/**
 * optional string position = 22;
 * @return {string}
 */
proto.mesop.Style.prototype.getPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setPosition = function(value) {
  return jspb.Message.setField(this, 22, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearPosition = function() {
  return jspb.Message.setField(this, 22, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string right = 55;
 * @return {string}
 */
proto.mesop.Style.prototype.getRight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setRight = function(value) {
  return jspb.Message.setField(this, 55, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearRight = function() {
  return jspb.Message.setField(this, 55, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasRight = function() {
  return jspb.Message.getField(this, 55) != null;
};


/**
 * optional string rotate = 66;
 * @return {string}
 */
proto.mesop.Style.prototype.getRotate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 66, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setRotate = function(value) {
  return jspb.Message.setField(this, 66, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearRotate = function() {
  return jspb.Message.setField(this, 66, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasRotate = function() {
  return jspb.Message.getField(this, 66) != null;
};


/**
 * optional string row_gap = 43;
 * @return {string}
 */
proto.mesop.Style.prototype.getRowGap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setRowGap = function(value) {
  return jspb.Message.setField(this, 43, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearRowGap = function() {
  return jspb.Message.setField(this, 43, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasRowGap = function() {
  return jspb.Message.getField(this, 43) != null;
};


/**
 * optional string text_align = 23;
 * @return {string}
 */
proto.mesop.Style.prototype.getTextAlign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setTextAlign = function(value) {
  return jspb.Message.setField(this, 23, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearTextAlign = function() {
  return jspb.Message.setField(this, 23, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasTextAlign = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional string text_decoration = 24;
 * @return {string}
 */
proto.mesop.Style.prototype.getTextDecoration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setTextDecoration = function(value) {
  return jspb.Message.setField(this, 24, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearTextDecoration = function() {
  return jspb.Message.setField(this, 24, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasTextDecoration = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string text_overflow = 25;
 * @return {string}
 */
proto.mesop.Style.prototype.getTextOverflow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setTextOverflow = function(value) {
  return jspb.Message.setField(this, 25, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearTextOverflow = function() {
  return jspb.Message.setField(this, 25, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasTextOverflow = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional string text_shadow = 77;
 * @return {string}
 */
proto.mesop.Style.prototype.getTextShadow = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 77, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setTextShadow = function(value) {
  return jspb.Message.setField(this, 77, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearTextShadow = function() {
  return jspb.Message.setField(this, 77, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasTextShadow = function() {
  return jspb.Message.getField(this, 77) != null;
};


/**
 * optional string text_transform = 78;
 * @return {string}
 */
proto.mesop.Style.prototype.getTextTransform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 78, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setTextTransform = function(value) {
  return jspb.Message.setField(this, 78, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearTextTransform = function() {
  return jspb.Message.setField(this, 78, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasTextTransform = function() {
  return jspb.Message.getField(this, 78) != null;
};


/**
 * optional string top = 56;
 * @return {string}
 */
proto.mesop.Style.prototype.getTop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setTop = function(value) {
  return jspb.Message.setField(this, 56, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearTop = function() {
  return jspb.Message.setField(this, 56, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasTop = function() {
  return jspb.Message.getField(this, 56) != null;
};


/**
 * optional string transform = 65;
 * @return {string}
 */
proto.mesop.Style.prototype.getTransform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setTransform = function(value) {
  return jspb.Message.setField(this, 65, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearTransform = function() {
  return jspb.Message.setField(this, 65, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasTransform = function() {
  return jspb.Message.getField(this, 65) != null;
};


/**
 * optional string transition = 79;
 * @return {string}
 */
proto.mesop.Style.prototype.getTransition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 79, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setTransition = function(value) {
  return jspb.Message.setField(this, 79, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearTransition = function() {
  return jspb.Message.setField(this, 79, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasTransition = function() {
  return jspb.Message.getField(this, 79) != null;
};


/**
 * optional string visibility = 51;
 * @return {string}
 */
proto.mesop.Style.prototype.getVisibility = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setVisibility = function(value) {
  return jspb.Message.setField(this, 51, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearVisibility = function() {
  return jspb.Message.setField(this, 51, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasVisibility = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * optional string white_space = 26;
 * @return {string}
 */
proto.mesop.Style.prototype.getWhiteSpace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setWhiteSpace = function(value) {
  return jspb.Message.setField(this, 26, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearWhiteSpace = function() {
  return jspb.Message.setField(this, 26, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasWhiteSpace = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional string width = 27;
 * @return {string}
 */
proto.mesop.Style.prototype.getWidth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setWidth = function(value) {
  return jspb.Message.setField(this, 27, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearWidth = function() {
  return jspb.Message.setField(this, 27, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasWidth = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional string word_wrap = 80;
 * @return {string}
 */
proto.mesop.Style.prototype.getWordWrap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setWordWrap = function(value) {
  return jspb.Message.setField(this, 80, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearWordWrap = function() {
  return jspb.Message.setField(this, 80, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasWordWrap = function() {
  return jspb.Message.getField(this, 80) != null;
};


/**
 * optional string vertical_align = 81;
 * @return {string}
 */
proto.mesop.Style.prototype.getVerticalAlign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 81, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setVerticalAlign = function(value) {
  return jspb.Message.setField(this, 81, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearVerticalAlign = function() {
  return jspb.Message.setField(this, 81, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasVerticalAlign = function() {
  return jspb.Message.getField(this, 81) != null;
};


/**
 * optional int32 z_index = 48;
 * @return {number}
 */
proto.mesop.Style.prototype.getZIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 48, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.setZIndex = function(value) {
  return jspb.Message.setField(this, 48, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.Style} returns this
 */
proto.mesop.Style.prototype.clearZIndex = function() {
  return jspb.Message.setField(this, 48, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Style.prototype.hasZIndex = function() {
  return jspb.Message.getField(this, 48) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.EdgeInsets.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.EdgeInsets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.EdgeInsets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.EdgeInsets.toObject = function(includeInstance, msg) {
  var f, obj = {
    top: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    bottom: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    left: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    right: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.EdgeInsets}
 */
proto.mesop.EdgeInsets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.EdgeInsets;
  return proto.mesop.EdgeInsets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.EdgeInsets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.EdgeInsets}
 */
proto.mesop.EdgeInsets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTop(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBottom(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeft(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.EdgeInsets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.EdgeInsets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.EdgeInsets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.EdgeInsets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string top = 1;
 * @return {string}
 */
proto.mesop.EdgeInsets.prototype.getTop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.EdgeInsets} returns this
 */
proto.mesop.EdgeInsets.prototype.setTop = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.EdgeInsets} returns this
 */
proto.mesop.EdgeInsets.prototype.clearTop = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.EdgeInsets.prototype.hasTop = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string bottom = 2;
 * @return {string}
 */
proto.mesop.EdgeInsets.prototype.getBottom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.EdgeInsets} returns this
 */
proto.mesop.EdgeInsets.prototype.setBottom = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.EdgeInsets} returns this
 */
proto.mesop.EdgeInsets.prototype.clearBottom = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.EdgeInsets.prototype.hasBottom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string left = 3;
 * @return {string}
 */
proto.mesop.EdgeInsets.prototype.getLeft = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.EdgeInsets} returns this
 */
proto.mesop.EdgeInsets.prototype.setLeft = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.EdgeInsets} returns this
 */
proto.mesop.EdgeInsets.prototype.clearLeft = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.EdgeInsets.prototype.hasLeft = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string right = 4;
 * @return {string}
 */
proto.mesop.EdgeInsets.prototype.getRight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.EdgeInsets} returns this
 */
proto.mesop.EdgeInsets.prototype.setRight = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.EdgeInsets} returns this
 */
proto.mesop.EdgeInsets.prototype.clearRight = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.EdgeInsets.prototype.hasRight = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.Border.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.Border.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.Border} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Border.toObject = function(includeInstance, msg) {
  var f, obj = {
    top: (f = msg.getTop()) && proto.mesop.BorderSide.toObject(includeInstance, f),
    bottom: (f = msg.getBottom()) && proto.mesop.BorderSide.toObject(includeInstance, f),
    left: (f = msg.getLeft()) && proto.mesop.BorderSide.toObject(includeInstance, f),
    right: (f = msg.getRight()) && proto.mesop.BorderSide.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.Border}
 */
proto.mesop.Border.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.Border;
  return proto.mesop.Border.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.Border} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.Border}
 */
proto.mesop.Border.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.BorderSide;
      reader.readMessage(value,proto.mesop.BorderSide.deserializeBinaryFromReader);
      msg.setTop(value);
      break;
    case 2:
      var value = new proto.mesop.BorderSide;
      reader.readMessage(value,proto.mesop.BorderSide.deserializeBinaryFromReader);
      msg.setBottom(value);
      break;
    case 3:
      var value = new proto.mesop.BorderSide;
      reader.readMessage(value,proto.mesop.BorderSide.deserializeBinaryFromReader);
      msg.setLeft(value);
      break;
    case 4:
      var value = new proto.mesop.BorderSide;
      reader.readMessage(value,proto.mesop.BorderSide.deserializeBinaryFromReader);
      msg.setRight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.Border.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.Border.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.Border} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.Border.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTop();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.BorderSide.serializeBinaryToWriter
    );
  }
  f = message.getBottom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.BorderSide.serializeBinaryToWriter
    );
  }
  f = message.getLeft();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mesop.BorderSide.serializeBinaryToWriter
    );
  }
  f = message.getRight();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mesop.BorderSide.serializeBinaryToWriter
    );
  }
};


/**
 * optional BorderSide top = 1;
 * @return {?proto.mesop.BorderSide}
 */
proto.mesop.Border.prototype.getTop = function() {
  return /** @type{?proto.mesop.BorderSide} */ (
    jspb.Message.getWrapperField(this, proto.mesop.BorderSide, 1));
};


/**
 * @param {?proto.mesop.BorderSide|undefined} value
 * @return {!proto.mesop.Border} returns this
*/
proto.mesop.Border.prototype.setTop = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Border} returns this
 */
proto.mesop.Border.prototype.clearTop = function() {
  return this.setTop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Border.prototype.hasTop = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BorderSide bottom = 2;
 * @return {?proto.mesop.BorderSide}
 */
proto.mesop.Border.prototype.getBottom = function() {
  return /** @type{?proto.mesop.BorderSide} */ (
    jspb.Message.getWrapperField(this, proto.mesop.BorderSide, 2));
};


/**
 * @param {?proto.mesop.BorderSide|undefined} value
 * @return {!proto.mesop.Border} returns this
*/
proto.mesop.Border.prototype.setBottom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Border} returns this
 */
proto.mesop.Border.prototype.clearBottom = function() {
  return this.setBottom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Border.prototype.hasBottom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BorderSide left = 3;
 * @return {?proto.mesop.BorderSide}
 */
proto.mesop.Border.prototype.getLeft = function() {
  return /** @type{?proto.mesop.BorderSide} */ (
    jspb.Message.getWrapperField(this, proto.mesop.BorderSide, 3));
};


/**
 * @param {?proto.mesop.BorderSide|undefined} value
 * @return {!proto.mesop.Border} returns this
*/
proto.mesop.Border.prototype.setLeft = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Border} returns this
 */
proto.mesop.Border.prototype.clearLeft = function() {
  return this.setLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Border.prototype.hasLeft = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional BorderSide right = 4;
 * @return {?proto.mesop.BorderSide}
 */
proto.mesop.Border.prototype.getRight = function() {
  return /** @type{?proto.mesop.BorderSide} */ (
    jspb.Message.getWrapperField(this, proto.mesop.BorderSide, 4));
};


/**
 * @param {?proto.mesop.BorderSide|undefined} value
 * @return {!proto.mesop.Border} returns this
*/
proto.mesop.Border.prototype.setRight = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.Border} returns this
 */
proto.mesop.Border.prototype.clearRight = function() {
  return this.setRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.Border.prototype.hasRight = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.BorderSide.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.BorderSide.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.BorderSide} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.BorderSide.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    color: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    style: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.BorderSide}
 */
proto.mesop.BorderSide.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.BorderSide;
  return proto.mesop.BorderSide.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.BorderSide} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.BorderSide}
 */
proto.mesop.BorderSide.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStyle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.BorderSide.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.BorderSide.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.BorderSide} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.BorderSide.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string width = 1;
 * @return {string}
 */
proto.mesop.BorderSide.prototype.getWidth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.BorderSide} returns this
 */
proto.mesop.BorderSide.prototype.setWidth = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.BorderSide} returns this
 */
proto.mesop.BorderSide.prototype.clearWidth = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.BorderSide.prototype.hasWidth = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.mesop.BorderSide.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.BorderSide} returns this
 */
proto.mesop.BorderSide.prototype.setColor = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.BorderSide} returns this
 */
proto.mesop.BorderSide.prototype.clearColor = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.BorderSide.prototype.hasColor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string style = 3;
 * @return {string}
 */
proto.mesop.BorderSide.prototype.getStyle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.BorderSide} returns this
 */
proto.mesop.BorderSide.prototype.setStyle = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.BorderSide} returns this
 */
proto.mesop.BorderSide.prototype.clearStyle = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.BorderSide.prototype.hasStyle = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.ComponentConfig.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ComponentConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ComponentConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ComponentConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ComponentConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    componentName: (f = msg.getComponentName()) && proto.mesop.ComponentName.toObject(includeInstance, f),
    category: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.mesop.EditorField.toObject, includeInstance),
    acceptsChild: (f = jspb.Message.getBooleanField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ComponentConfig}
 */
proto.mesop.ComponentConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ComponentConfig;
  return proto.mesop.ComponentConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ComponentConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ComponentConfig}
 */
proto.mesop.ComponentConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.ComponentName;
      reader.readMessage(value,proto.mesop.ComponentName.deserializeBinaryFromReader);
      msg.setComponentName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = new proto.mesop.EditorField;
      reader.readMessage(value,proto.mesop.EditorField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAcceptsChild(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ComponentConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ComponentConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ComponentConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ComponentConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComponentName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.ComponentName.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mesop.EditorField.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional ComponentName component_name = 1;
 * @return {?proto.mesop.ComponentName}
 */
proto.mesop.ComponentConfig.prototype.getComponentName = function() {
  return /** @type{?proto.mesop.ComponentName} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ComponentName, 1));
};


/**
 * @param {?proto.mesop.ComponentName|undefined} value
 * @return {!proto.mesop.ComponentConfig} returns this
*/
proto.mesop.ComponentConfig.prototype.setComponentName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.ComponentConfig} returns this
 */
proto.mesop.ComponentConfig.prototype.clearComponentName = function() {
  return this.setComponentName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentConfig.prototype.hasComponentName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.mesop.ComponentConfig.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.ComponentConfig} returns this
 */
proto.mesop.ComponentConfig.prototype.setCategory = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentConfig} returns this
 */
proto.mesop.ComponentConfig.prototype.clearCategory = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentConfig.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated EditorField fields = 3;
 * @return {!Array<!proto.mesop.EditorField>}
 */
proto.mesop.ComponentConfig.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.mesop.EditorField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.EditorField, 3));
};


/**
 * @param {!Array<!proto.mesop.EditorField>} value
 * @return {!proto.mesop.ComponentConfig} returns this
*/
proto.mesop.ComponentConfig.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mesop.EditorField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.EditorField}
 */
proto.mesop.ComponentConfig.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mesop.EditorField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.ComponentConfig} returns this
 */
proto.mesop.ComponentConfig.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional bool accepts_child = 4;
 * @return {boolean}
 */
proto.mesop.ComponentConfig.prototype.getAcceptsChild = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.ComponentConfig} returns this
 */
proto.mesop.ComponentConfig.prototype.setAcceptsChild = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentConfig} returns this
 */
proto.mesop.ComponentConfig.prototype.clearAcceptsChild = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentConfig.prototype.hasAcceptsChild = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mesop.ComponentName.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.mesop.ComponentName.ModuleIdCase = {
  MODULE_ID_NOT_SET: 0,
  MODULE_PATH: 1,
  CORE_MODULE: 2
};

/**
 * @return {proto.mesop.ComponentName.ModuleIdCase}
 */
proto.mesop.ComponentName.prototype.getModuleIdCase = function() {
  return /** @type {proto.mesop.ComponentName.ModuleIdCase} */(jspb.Message.computeOneofCase(this, proto.mesop.ComponentName.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ComponentName.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ComponentName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ComponentName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ComponentName.toObject = function(includeInstance, msg) {
  var f, obj = {
    modulePath: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    coreModule: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    fnName: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ComponentName}
 */
proto.mesop.ComponentName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ComponentName;
  return proto.mesop.ComponentName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ComponentName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ComponentName}
 */
proto.mesop.ComponentName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModulePath(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCoreModule(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFnName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ComponentName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ComponentName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ComponentName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ComponentName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string module_path = 1;
 * @return {string}
 */
proto.mesop.ComponentName.prototype.getModulePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.ComponentName} returns this
 */
proto.mesop.ComponentName.prototype.setModulePath = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.mesop.ComponentName.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentName} returns this
 */
proto.mesop.ComponentName.prototype.clearModulePath = function() {
  return jspb.Message.setOneofField(this, 1, proto.mesop.ComponentName.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentName.prototype.hasModulePath = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool core_module = 2;
 * @return {boolean}
 */
proto.mesop.ComponentName.prototype.getCoreModule = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.ComponentName} returns this
 */
proto.mesop.ComponentName.prototype.setCoreModule = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.mesop.ComponentName.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentName} returns this
 */
proto.mesop.ComponentName.prototype.clearCoreModule = function() {
  return jspb.Message.setOneofField(this, 2, proto.mesop.ComponentName.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentName.prototype.hasCoreModule = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string fn_name = 3;
 * @return {string}
 */
proto.mesop.ComponentName.prototype.getFnName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.ComponentName} returns this
 */
proto.mesop.ComponentName.prototype.setFnName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.ComponentName} returns this
 */
proto.mesop.ComponentName.prototype.clearFnName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ComponentName.prototype.hasFnName = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.EditorField.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.EditorField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.EditorField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.EditorField.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    type: (f = msg.getType()) && proto.mesop.FieldType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.EditorField}
 */
proto.mesop.EditorField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.EditorField;
  return proto.mesop.EditorField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.EditorField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.EditorField}
 */
proto.mesop.EditorField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.mesop.FieldType;
      reader.readMessage(value,proto.mesop.FieldType.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.EditorField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.EditorField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.EditorField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.EditorField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.FieldType.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.mesop.EditorField.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.EditorField} returns this
 */
proto.mesop.EditorField.prototype.setName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.EditorField} returns this
 */
proto.mesop.EditorField.prototype.clearName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.EditorField.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FieldType type = 2;
 * @return {?proto.mesop.FieldType}
 */
proto.mesop.EditorField.prototype.getType = function() {
  return /** @type{?proto.mesop.FieldType} */ (
    jspb.Message.getWrapperField(this, proto.mesop.FieldType, 2));
};


/**
 * @param {?proto.mesop.FieldType|undefined} value
 * @return {!proto.mesop.EditorField} returns this
*/
proto.mesop.EditorField.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.EditorField} returns this
 */
proto.mesop.EditorField.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.EditorField.prototype.hasType = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mesop.FieldType.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.mesop.FieldType.TypeCase = {
  TYPE_NOT_SET: 0,
  BOOL_TYPE: 1,
  INT_TYPE: 2,
  FLOAT_TYPE: 3,
  STRING_TYPE: 4,
  LITERAL_TYPE: 5,
  LIST_TYPE: 6,
  STRUCT_TYPE: 7
};

/**
 * @return {proto.mesop.FieldType.TypeCase}
 */
proto.mesop.FieldType.prototype.getTypeCase = function() {
  return /** @type {proto.mesop.FieldType.TypeCase} */(jspb.Message.computeOneofCase(this, proto.mesop.FieldType.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.FieldType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.FieldType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.FieldType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.FieldType.toObject = function(includeInstance, msg) {
  var f, obj = {
    boolType: (f = msg.getBoolType()) && proto.mesop.BoolType.toObject(includeInstance, f),
    intType: (f = msg.getIntType()) && proto.mesop.IntType.toObject(includeInstance, f),
    floatType: (f = msg.getFloatType()) && proto.mesop.FloatType.toObject(includeInstance, f),
    stringType: (f = msg.getStringType()) && proto.mesop.StringType.toObject(includeInstance, f),
    literalType: (f = msg.getLiteralType()) && proto.mesop.LiteralType.toObject(includeInstance, f),
    listType: (f = msg.getListType()) && proto.mesop.ListType.toObject(includeInstance, f),
    structType: (f = msg.getStructType()) && proto.mesop.StructType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.FieldType}
 */
proto.mesop.FieldType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.FieldType;
  return proto.mesop.FieldType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.FieldType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.FieldType}
 */
proto.mesop.FieldType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.BoolType;
      reader.readMessage(value,proto.mesop.BoolType.deserializeBinaryFromReader);
      msg.setBoolType(value);
      break;
    case 2:
      var value = new proto.mesop.IntType;
      reader.readMessage(value,proto.mesop.IntType.deserializeBinaryFromReader);
      msg.setIntType(value);
      break;
    case 3:
      var value = new proto.mesop.FloatType;
      reader.readMessage(value,proto.mesop.FloatType.deserializeBinaryFromReader);
      msg.setFloatType(value);
      break;
    case 4:
      var value = new proto.mesop.StringType;
      reader.readMessage(value,proto.mesop.StringType.deserializeBinaryFromReader);
      msg.setStringType(value);
      break;
    case 5:
      var value = new proto.mesop.LiteralType;
      reader.readMessage(value,proto.mesop.LiteralType.deserializeBinaryFromReader);
      msg.setLiteralType(value);
      break;
    case 6:
      var value = new proto.mesop.ListType;
      reader.readMessage(value,proto.mesop.ListType.deserializeBinaryFromReader);
      msg.setListType(value);
      break;
    case 7:
      var value = new proto.mesop.StructType;
      reader.readMessage(value,proto.mesop.StructType.deserializeBinaryFromReader);
      msg.setStructType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.FieldType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.FieldType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.FieldType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.FieldType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBoolType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.BoolType.serializeBinaryToWriter
    );
  }
  f = message.getIntType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.IntType.serializeBinaryToWriter
    );
  }
  f = message.getFloatType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mesop.FloatType.serializeBinaryToWriter
    );
  }
  f = message.getStringType();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mesop.StringType.serializeBinaryToWriter
    );
  }
  f = message.getLiteralType();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mesop.LiteralType.serializeBinaryToWriter
    );
  }
  f = message.getListType();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.mesop.ListType.serializeBinaryToWriter
    );
  }
  f = message.getStructType();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mesop.StructType.serializeBinaryToWriter
    );
  }
};


/**
 * optional BoolType bool_type = 1;
 * @return {?proto.mesop.BoolType}
 */
proto.mesop.FieldType.prototype.getBoolType = function() {
  return /** @type{?proto.mesop.BoolType} */ (
    jspb.Message.getWrapperField(this, proto.mesop.BoolType, 1));
};


/**
 * @param {?proto.mesop.BoolType|undefined} value
 * @return {!proto.mesop.FieldType} returns this
*/
proto.mesop.FieldType.prototype.setBoolType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.mesop.FieldType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.FieldType} returns this
 */
proto.mesop.FieldType.prototype.clearBoolType = function() {
  return this.setBoolType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.FieldType.prototype.hasBoolType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional IntType int_type = 2;
 * @return {?proto.mesop.IntType}
 */
proto.mesop.FieldType.prototype.getIntType = function() {
  return /** @type{?proto.mesop.IntType} */ (
    jspb.Message.getWrapperField(this, proto.mesop.IntType, 2));
};


/**
 * @param {?proto.mesop.IntType|undefined} value
 * @return {!proto.mesop.FieldType} returns this
*/
proto.mesop.FieldType.prototype.setIntType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.mesop.FieldType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.FieldType} returns this
 */
proto.mesop.FieldType.prototype.clearIntType = function() {
  return this.setIntType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.FieldType.prototype.hasIntType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FloatType float_type = 3;
 * @return {?proto.mesop.FloatType}
 */
proto.mesop.FieldType.prototype.getFloatType = function() {
  return /** @type{?proto.mesop.FloatType} */ (
    jspb.Message.getWrapperField(this, proto.mesop.FloatType, 3));
};


/**
 * @param {?proto.mesop.FloatType|undefined} value
 * @return {!proto.mesop.FieldType} returns this
*/
proto.mesop.FieldType.prototype.setFloatType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.mesop.FieldType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.FieldType} returns this
 */
proto.mesop.FieldType.prototype.clearFloatType = function() {
  return this.setFloatType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.FieldType.prototype.hasFloatType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional StringType string_type = 4;
 * @return {?proto.mesop.StringType}
 */
proto.mesop.FieldType.prototype.getStringType = function() {
  return /** @type{?proto.mesop.StringType} */ (
    jspb.Message.getWrapperField(this, proto.mesop.StringType, 4));
};


/**
 * @param {?proto.mesop.StringType|undefined} value
 * @return {!proto.mesop.FieldType} returns this
*/
proto.mesop.FieldType.prototype.setStringType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.mesop.FieldType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.FieldType} returns this
 */
proto.mesop.FieldType.prototype.clearStringType = function() {
  return this.setStringType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.FieldType.prototype.hasStringType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional LiteralType literal_type = 5;
 * @return {?proto.mesop.LiteralType}
 */
proto.mesop.FieldType.prototype.getLiteralType = function() {
  return /** @type{?proto.mesop.LiteralType} */ (
    jspb.Message.getWrapperField(this, proto.mesop.LiteralType, 5));
};


/**
 * @param {?proto.mesop.LiteralType|undefined} value
 * @return {!proto.mesop.FieldType} returns this
*/
proto.mesop.FieldType.prototype.setLiteralType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.mesop.FieldType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.FieldType} returns this
 */
proto.mesop.FieldType.prototype.clearLiteralType = function() {
  return this.setLiteralType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.FieldType.prototype.hasLiteralType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ListType list_type = 6;
 * @return {?proto.mesop.ListType}
 */
proto.mesop.FieldType.prototype.getListType = function() {
  return /** @type{?proto.mesop.ListType} */ (
    jspb.Message.getWrapperField(this, proto.mesop.ListType, 6));
};


/**
 * @param {?proto.mesop.ListType|undefined} value
 * @return {!proto.mesop.FieldType} returns this
*/
proto.mesop.FieldType.prototype.setListType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.mesop.FieldType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.FieldType} returns this
 */
proto.mesop.FieldType.prototype.clearListType = function() {
  return this.setListType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.FieldType.prototype.hasListType = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional StructType struct_type = 7;
 * @return {?proto.mesop.StructType}
 */
proto.mesop.FieldType.prototype.getStructType = function() {
  return /** @type{?proto.mesop.StructType} */ (
    jspb.Message.getWrapperField(this, proto.mesop.StructType, 7));
};


/**
 * @param {?proto.mesop.StructType|undefined} value
 * @return {!proto.mesop.FieldType} returns this
*/
proto.mesop.FieldType.prototype.setStructType = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.mesop.FieldType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.FieldType} returns this
 */
proto.mesop.FieldType.prototype.clearStructType = function() {
  return this.setStructType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.FieldType.prototype.hasStructType = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.BoolType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.BoolType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.BoolType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.BoolType.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultValue: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.BoolType}
 */
proto.mesop.BoolType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.BoolType;
  return proto.mesop.BoolType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.BoolType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.BoolType}
 */
proto.mesop.BoolType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.BoolType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.BoolType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.BoolType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.BoolType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool default_value = 1;
 * @return {boolean}
 */
proto.mesop.BoolType.prototype.getDefaultValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.BoolType} returns this
 */
proto.mesop.BoolType.prototype.setDefaultValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.BoolType} returns this
 */
proto.mesop.BoolType.prototype.clearDefaultValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.BoolType.prototype.hasDefaultValue = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.StructType.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.StructType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.StructType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.StructType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.StructType.toObject = function(includeInstance, msg) {
  var f, obj = {
    structName: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    fieldsList: jspb.Message.toObjectList(msg.getFieldsList(),
    proto.mesop.EditorField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.StructType}
 */
proto.mesop.StructType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.StructType;
  return proto.mesop.StructType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.StructType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.StructType}
 */
proto.mesop.StructType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStructName(value);
      break;
    case 1:
      var value = new proto.mesop.EditorField;
      reader.readMessage(value,proto.mesop.EditorField.deserializeBinaryFromReader);
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.StructType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.StructType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.StructType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.StructType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mesop.EditorField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string struct_name = 2;
 * @return {string}
 */
proto.mesop.StructType.prototype.getStructName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.StructType} returns this
 */
proto.mesop.StructType.prototype.setStructName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.StructType} returns this
 */
proto.mesop.StructType.prototype.clearStructName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.StructType.prototype.hasStructName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated EditorField fields = 1;
 * @return {!Array<!proto.mesop.EditorField>}
 */
proto.mesop.StructType.prototype.getFieldsList = function() {
  return /** @type{!Array<!proto.mesop.EditorField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.EditorField, 1));
};


/**
 * @param {!Array<!proto.mesop.EditorField>} value
 * @return {!proto.mesop.StructType} returns this
*/
proto.mesop.StructType.prototype.setFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mesop.EditorField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.EditorField}
 */
proto.mesop.StructType.prototype.addFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mesop.EditorField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.StructType} returns this
 */
proto.mesop.StructType.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.IntType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.IntType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.IntType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.IntType.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultValue: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.IntType}
 */
proto.mesop.IntType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.IntType;
  return proto.mesop.IntType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.IntType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.IntType}
 */
proto.mesop.IntType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDefaultValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.IntType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.IntType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.IntType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.IntType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 default_value = 1;
 * @return {number}
 */
proto.mesop.IntType.prototype.getDefaultValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.IntType} returns this
 */
proto.mesop.IntType.prototype.setDefaultValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.IntType} returns this
 */
proto.mesop.IntType.prototype.clearDefaultValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.IntType.prototype.hasDefaultValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.FloatType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.FloatType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.FloatType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.FloatType.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultValue: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.FloatType}
 */
proto.mesop.FloatType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.FloatType;
  return proto.mesop.FloatType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.FloatType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.FloatType}
 */
proto.mesop.FloatType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDefaultValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.FloatType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.FloatType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.FloatType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.FloatType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double default_value = 1;
 * @return {number}
 */
proto.mesop.FloatType.prototype.getDefaultValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.FloatType} returns this
 */
proto.mesop.FloatType.prototype.setDefaultValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.FloatType} returns this
 */
proto.mesop.FloatType.prototype.clearDefaultValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.FloatType.prototype.hasDefaultValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.StringType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.StringType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.StringType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.StringType.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultValue: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.StringType}
 */
proto.mesop.StringType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.StringType;
  return proto.mesop.StringType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.StringType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.StringType}
 */
proto.mesop.StringType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.StringType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.StringType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.StringType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.StringType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string default_value = 1;
 * @return {string}
 */
proto.mesop.StringType.prototype.getDefaultValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.StringType} returns this
 */
proto.mesop.StringType.prototype.setDefaultValue = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.StringType} returns this
 */
proto.mesop.StringType.prototype.clearDefaultValue = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.StringType.prototype.hasDefaultValue = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.LiteralType.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.LiteralType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.LiteralType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.LiteralType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.LiteralType.toObject = function(includeInstance, msg) {
  var f, obj = {
    literalsList: jspb.Message.toObjectList(msg.getLiteralsList(),
    proto.mesop.LiteralElement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.LiteralType}
 */
proto.mesop.LiteralType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.LiteralType;
  return proto.mesop.LiteralType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.LiteralType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.LiteralType}
 */
proto.mesop.LiteralType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.LiteralElement;
      reader.readMessage(value,proto.mesop.LiteralElement.deserializeBinaryFromReader);
      msg.addLiterals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.LiteralType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.LiteralType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.LiteralType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.LiteralType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiteralsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mesop.LiteralElement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LiteralElement literals = 1;
 * @return {!Array<!proto.mesop.LiteralElement>}
 */
proto.mesop.LiteralType.prototype.getLiteralsList = function() {
  return /** @type{!Array<!proto.mesop.LiteralElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.LiteralElement, 1));
};


/**
 * @param {!Array<!proto.mesop.LiteralElement>} value
 * @return {!proto.mesop.LiteralType} returns this
*/
proto.mesop.LiteralType.prototype.setLiteralsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mesop.LiteralElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.LiteralElement}
 */
proto.mesop.LiteralType.prototype.addLiterals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mesop.LiteralElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.LiteralType} returns this
 */
proto.mesop.LiteralType.prototype.clearLiteralsList = function() {
  return this.setLiteralsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mesop.LiteralElement.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.mesop.LiteralElement.LiteralCase = {
  LITERAL_NOT_SET: 0,
  STRING_LITERAL: 1,
  INT_LITERAL: 2
};

/**
 * @return {proto.mesop.LiteralElement.LiteralCase}
 */
proto.mesop.LiteralElement.prototype.getLiteralCase = function() {
  return /** @type {proto.mesop.LiteralElement.LiteralCase} */(jspb.Message.computeOneofCase(this, proto.mesop.LiteralElement.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.LiteralElement.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.LiteralElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.LiteralElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.LiteralElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    stringLiteral: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    intLiteral: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.LiteralElement}
 */
proto.mesop.LiteralElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.LiteralElement;
  return proto.mesop.LiteralElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.LiteralElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.LiteralElement}
 */
proto.mesop.LiteralElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringLiteral(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntLiteral(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.LiteralElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.LiteralElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.LiteralElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.LiteralElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string string_literal = 1;
 * @return {string}
 */
proto.mesop.LiteralElement.prototype.getStringLiteral = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.LiteralElement} returns this
 */
proto.mesop.LiteralElement.prototype.setStringLiteral = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.mesop.LiteralElement.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.LiteralElement} returns this
 */
proto.mesop.LiteralElement.prototype.clearStringLiteral = function() {
  return jspb.Message.setOneofField(this, 1, proto.mesop.LiteralElement.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.LiteralElement.prototype.hasStringLiteral = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 int_literal = 2;
 * @return {number}
 */
proto.mesop.LiteralElement.prototype.getIntLiteral = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.LiteralElement} returns this
 */
proto.mesop.LiteralElement.prototype.setIntLiteral = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.mesop.LiteralElement.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.LiteralElement} returns this
 */
proto.mesop.LiteralElement.prototype.clearIntLiteral = function() {
  return jspb.Message.setOneofField(this, 2, proto.mesop.LiteralElement.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.LiteralElement.prototype.hasIntLiteral = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.ListType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.ListType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.ListType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ListType.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: (f = msg.getType()) && proto.mesop.FieldType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.ListType}
 */
proto.mesop.ListType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.ListType;
  return proto.mesop.ListType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.ListType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.ListType}
 */
proto.mesop.ListType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.FieldType;
      reader.readMessage(value,proto.mesop.FieldType.deserializeBinaryFromReader);
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.ListType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.ListType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.ListType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.ListType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.FieldType.serializeBinaryToWriter
    );
  }
};


/**
 * optional FieldType type = 1;
 * @return {?proto.mesop.FieldType}
 */
proto.mesop.ListType.prototype.getType = function() {
  return /** @type{?proto.mesop.FieldType} */ (
    jspb.Message.getWrapperField(this, proto.mesop.FieldType, 1));
};


/**
 * @param {?proto.mesop.FieldType|undefined} value
 * @return {!proto.mesop.ListType} returns this
*/
proto.mesop.ListType.prototype.setType = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.ListType} returns this
 */
proto.mesop.ListType.prototype.clearType = function() {
  return this.setType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.ListType.prototype.hasType = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.mesop.ThemeMode = {
  THEME_MODE_SYSTEM: 0,
  THEME_MODE_LIGHT: 1,
  THEME_MODE_DARK: 2
};

goog.object.extend(exports, proto.mesop);
